<template>
    <div class="statistics-wrapper" @click="popCoverHide">
        <div class="warning-setting">
            <el-popover placement="bottom-start" title="综合预警设置" width="320" trigger="click" @show="setWarningValueKey" ref="warningSetting">
                <div class="r-flex r-pt-10">
                    <p class="r-pr-10">预警临界</p>
                    <el-input-number size="small" :controls="false" v-model="warningValueKey" :min="0"
                        :max="100"></el-input-number>
                    <p class="r-pl-10">%</p>
                </div>
                <div class="r-pt-20">
                    <el-alert :title="'当综合进度小于此值时显示红色\n当综合进度大于等于此值时显示绿色'" type="warning" show-icon :closable="false">
                    </el-alert>
                </div>
                <div class="r-pt-40 r-row-right r-flex">
                    <el-button type="primary" size="small" @click="saveWarningValue" :loading="saveWarningValueLoading">{{ saveWarningValueLoading ? '保存中...' : '保存' }}</el-button>
                </div>
                <i class="el-icon-setting" slot="reference" style="color:var(--primary)"></i>
            </el-popover>
        </div>
        <el-card class="section" shadow="never">
            <div class="section-search r-flex r-row-right">
                <el-popover placement="bottom-end" width="500" trigger="manual" v-model="indicatorFormPopShow"
                    @click.stop.native>
                    <div>
                        <div class="r-pb-20">筛选条件</div>
                        <el-form size="small" label-width="100px" label-position="left">
                            <el-form-item label="指标级别">
                                <el-select v-model="indicatorSearchForm.level" placeholder="请选择指标级别" class="r-w-max"
                                    clearable>
                                    <el-option v-for="item in [
                                        { label: '一级指标', value: 1 },
                                        { label: '二级指标', value: 2 },
                                        { label: '三级指标', value: 3 },
                                        { label: '四级指标', value: 4 },
                                        { label: '末级指标', value: -1 },
                                    ]" :key="item.value" :label="item.label" :value="item.value">
                                    </el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item label="负责部门">
                                <r-tag-select v-model="indicatorSearchForm.department" class="r-w-max"
                                    :collapseTags="true" placeholder="请选择部门"
                                    @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department', 'indicatorSearchForm')"
                                    labelKey="name"></r-tag-select>
                            </el-form-item>
                            <el-form-item label="协作部门">
                                <r-tag-select v-model="indicatorSearchForm.cooperateDepartment" class="r-w-max"
                                    :collapseTags="true" placeholder="请选择协作部门"
                                    @focus="handleChangeSelect('checkbox', '请选择协作部门', 'cooperateDepartment', 'indicatorSearchForm')"
                                    labelKey="name"></r-tag-select>
                            </el-form-item>
                            <el-form-item label="计划开始时间">
                                <div class="r-flex">
                                    <el-date-picker class="r-w-max" v-model="indicatorSearchForm.startTime"
                                        type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="计划结束时间">
                                <div class="r-flex">
                                    <el-date-picker class="r-w-max" v-model="indicatorSearchForm.endTime"
                                        type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                        start-placeholder="开始日期" end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <div class="r-flex r-row-right">
                                    <el-button @click="popHide('indicatorFormPopShow')">取消</el-button>
                                    <el-button type="primary" @click="indicatorSearchConfirm">确认</el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-button slot="reference" class="r-mr-10 sx-btn"
                        @click="popOpen('indicator', 'indicatorFormPopShow')" :class="{ active: indicatorSearchActive }">
                        <r-icon name="shaixuan" type="task" size="16"></r-icon>
                    </el-button>
                </el-popover>
            </div>
            <div class="section-top r-flex r-col-top">
                <div class="section-title">指标任务概况：</div>
                <div class="section-top-extend r-flex r-row-bet r-flex-auto-w">
                    <div class="section-top-item r-flex r-flex-column r-row-center r-flex-1"
                        v-for="(item, index) in indicatorCount" :key="index">
                        <p :style="{ color: parseFloat(item.value) >= warningValue ? 'green' : 'red' }"
                            v-if="item.prop == 'progress'">{{ item.value }}</p>
                        <p v-else>{{ item.value }}</p>
                        <p>{{ item.label }}</p>
                    </div>
                </div>
            </div>
            <div class="section-content r-flex section-cover">
                <div class="section-block r-flex r-mr-30 r-flex-1" v-if="waitIndicator">
                    <div class="section-block-title">
                        <span class="r-flex r-flex-column r-row-center">未启动</span>
                    </div>
                    <div class="list r-flex-auto-w">
                        <div class="r-flex-1 hover--opacity"
                            @click="linkPage({ name: 'handle-indicator', params: { lastStatus: [waitIndicator.prop],...getIndicatorForm(true) } })">
                            <el-card class="status-item">
                                <div class="r-flex r-row-bet section-item">
                                    <div class="status-item-l">
                                        <p :style="{ color: waitIndicator.color }" class="r-flex">
                                            <span :style="{ background: waitIndicator.color }"></span>
                                            <span>{{ waitIndicator.label }}</span>
                                        </p>
                                        <p>
                                            <span>{{ waitIndicator.value }}</span>
                                            <span>项</span>
                                        </p>
                                    </div>
                                    <div class="status-item-r">
                                        <el-progress type="circle" :percentage="waitIndicator.ratio"
                                            :color="waitIndicator.color" :width="progressWidth"
                                            :stroke-width="10"></el-progress>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <div class="section-block r-flex r-flex-4" v-if="indicatorStatus.length > 0">
                    <div class="section-block-title" style="background:rgb(29, 127, 254)">
                        <span class="r-flex r-flex-column r-row-center">已启动</span>
                    </div>
                    <div class="list r-flex r-flex-auto-w">
                        <div class="r-flex-1 hover--opacity" v-for="(item, index) in indicatorStatus" :key="index"
                            :class="{ 'r-ml-20': index > 0 }"
                            @click="linkPage({ name: 'handle-indicator', params: { lastStatus: [item.prop],...getIndicatorForm(true)  } })">
                            <el-card class="status-item">
                                <div class="r-flex r-row-bet section-item">
                                    <div class="status-item-l">
                                        <p :style="{ color: item.color }" class="r-flex">
                                            <span :style="{ background: item.color }"></span>
                                            <span>{{ item.label }}</span>
                                        </p>
                                        <p>
                                            <span>{{ item.value }}</span>
                                            <span>项</span>
                                        </p>
                                    </div>
                                    <div class="status-item-r">
                                        <el-progress type="circle" :percentage="item.ratio" :color="item.color"
                                            :width="progressWidth" :stroke-width="10"></el-progress>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="section r-mt-30" shadow="never">
            <div class="section-search r-flex r-row-right">
                <el-popover placement="bottom-end" width="500" trigger="manual" v-model="taskFormPopShow"
                    @click.stop.native>
                    <div>
                        <div class="r-pb-20">筛选条件</div>
                        <el-form size="small" label-width="100px" label-position="left">
                            <el-form-item label="负责部门">
                                <r-tag-select v-model="taskSearchForm.department" class="r-w-max" :collapseTags="true"
                                    placeholder="请选择部门"
                                    @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department', 'taskSearchForm')"
                                    labelKey="name"></r-tag-select>
                            </el-form-item>
                            <el-form-item label="协作部门">
                                <r-tag-select v-model="taskSearchForm.cooperateDepartment" class="r-w-max"
                                    :collapseTags="true" placeholder="请选择协作部门"
                                    @focus="handleChangeSelect('checkbox', '请选择协作部门', 'cooperateDepartment', 'taskSearchForm')"
                                    labelKey="name"></r-tag-select>
                            </el-form-item>
                            <el-form-item label="计划开始时间">
                                <div class="r-flex">
                                    <el-date-picker class="r-w-max" v-model="taskSearchForm.startDate" type="daterange"
                                        range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item label="计划结束时间">
                                <div class="r-flex">
                                    <el-date-picker class="r-w-max" v-model="taskSearchForm.endDate" type="daterange"
                                        range-separator="至" value-format="yyyy-MM-dd" start-placeholder="开始日期"
                                        end-placeholder="结束日期">
                                    </el-date-picker>
                                </div>
                            </el-form-item>
                            <el-form-item>
                                <div class="r-flex r-row-right">
                                    <el-button @click="popHide('taskFormPopShow')">取消</el-button>
                                    <el-button type="primary" @click="taskSearchConfirm">确认</el-button>
                                </div>
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-button slot="reference" class="r-mr-10 sx-btn"
                        @click="popOpen('task', 'taskFormPopShow')" :class="{ active: taskSearchActive }">
                        <r-icon name="shaixuan" type="task" size="16"></r-icon>
                    </el-button>
                </el-popover>
            </div>
            <div class="section-top r-flex r-col-top">
                <div class="section-title">子任务概况：</div>
                <div class="section-top-extend r-flex r-row-bet r-flex-auto-w">
                    <div class="section-top-item r-flex r-flex-column r-row-center r-flex-1"
                        v-for="(item, index) in taskCount" :key="index">
                        <p :style="{ color: parseFloat(item.value) >= warningValue ? 'green' : 'red' }"
                            v-if="item.prop == 'progress'">{{ item.value }}</p>
                        <p v-else>{{ item.value }}</p>
                        <p>{{ item.label }}</p>
                    </div>
                </div>
            </div>
            <div class="section-content r-flex section-cover">
                <div class="section-block r-flex r-mr-30 r-flex-1" v-if="waitTask">
                    <div class="section-block-title">
                        <span class="r-flex r-flex-column r-row-center">未启动</span>
                    </div>
                    <div class="list r-flex-auto-w">
                        <div class="r-flex-1 hover--opacity" @click="linkPage({ name: 'handle-indicator-task-list', params: { status: waitTask.taskStatus, ...getTaskForm(true) } })">
                            <el-card class="status-item">
                                <div>
                                    <div class="r-flex r-row-bet section-item">
                                        <div class="status-item-l">
                                            <p :style="{ color: waitTask.color }" class="r-flex">
                                                <span :style="{ background: waitTask.color }"></span>
                                                <span>{{ waitTask.label }}</span>
                                            </p>
                                            <p>
                                                <span>{{ waitTask.value }}</span>
                                                <span>项</span>
                                            </p>
                                        </div>
                                        <div class="status-item-r">
                                            <el-progress type="circle" :percentage="waitTask.ratio" :color="waitTask.color"
                                                :width="progressWidth" :stroke-width="10"></el-progress>
                                        </div>
                                    </div>
                                    <div class="r-flex sub-item" :class="{ 'r-border-top': waitTask.children.length > 0 }">
                                        <div class="r-flex-1 r-row-bet r-flex sub-item-content"
                                            :class="{ 'r-border-right': _index != waitTask.children.length - 1 }"
                                            v-for="(_item, _index) in waitTask.children" :key="_index">
                                            <span>{{ _item.label }}</span>
                                            <span>{{ _item.value }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
                <div class="section-block r-flex r-flex-4" v-if="taskStatus.length > 0">
                    <div class="section-block-title" style="background:rgb(29, 127, 254)">
                        <span class="r-flex r-flex-column r-row-center">已启动</span>
                    </div>
                    <div class="list r-flex r-flex-auto-w">
                        <div class="r-flex-1 hover--opacity" v-for="(item, index) in taskStatus" :key="index"
                            :class="{ 'r-ml-30': index > 0 }"
                            @click="linkPage({ name: 'handle-indicator-task-list', params: { status: item.taskStatus, ...getTaskForm(true) } })">
                            <el-card class="status-item">
                                <div>
                                    <div class="r-flex r-row-bet section-item">
                                        <div class="status-item-l">
                                            <p :style="{ color: item.color }" class="r-flex">
                                                <span :style="{ background: item.color }"></span>
                                                <span>{{ item.label }}</span>
                                            </p>
                                            <p>
                                                <span>{{ item.value }}</span>
                                                <span>项</span>
                                            </p>
                                        </div>
                                        <div class="status-item-r">
                                            <el-progress type="circle" :percentage="item.ratio" :color="item.color"
                                                :width="progressWidth" :stroke-width="10"></el-progress>
                                        </div>
                                    </div>
                                    <div class="r-flex sub-item" :class="{ 'r-border-top': item.children.length > 0 }">
                                        <div class="r-flex-1 r-row-bet r-flex sub-item-content"
                                            :class="{ 'r-border-right': _index != item.children.length - 1 }"
                                            v-for="(_item, _index) in item.children" :key="_index">
                                            <span>{{ _item.label }}</span>
                                            <span>{{ _item.value }}</span>
                                        </div>
                                    </div>
                                </div>
                            </el-card>
                        </div>
                    </div>
                </div>
            </div>
        </el-card>
        <el-card class="section r-mt-30" shadow="never">
            <div class="section-top r-flex r-col-center">
                <div class="section-title w-auto">一级指标完成情况：</div>
                <div class="r-flex r-row-right r-flex-auto-w">
                    <el-popover placement="bottom-end" width="500" trigger="manual" v-model="topIndicatorFormPopShow"
                        @click.stop.native>
                        <div>
                            <div class="r-pb-20">筛选条件</div>
                            <el-form size="small" label-width="100px" label-position="left">
                                <el-form-item label="负责部门">
                                    <r-tag-select v-model="topIndicatorSearchForm.department" class="r-w-max"
                                        :collapseTags="true" placeholder="请选择部门"
                                        @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department', 'topIndicatorSearchForm')"
                                        labelKey="name"></r-tag-select>
                                </el-form-item>
                                <el-form-item label="协作部门">
                                    <r-tag-select v-model="topIndicatorSearchForm.cooperateDepartment" class="r-w-max"
                                        :collapseTags="true" placeholder="请选择协作部门"
                                        @focus="handleChangeSelect('checkbox', '请选择协作部门', 'cooperateDepartment', 'topIndicatorSearchForm')"
                                        labelKey="name"></r-tag-select>
                                </el-form-item>
                                <el-form-item label="计划开始时间">
                                    <div class="r-flex">
                                        <el-date-picker class="r-w-max" v-model="topIndicatorSearchForm.startTime"
                                            type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期" end-placeholder="结束日期">
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                                <el-form-item label="计划结束时间">
                                    <div class="r-flex">
                                        <el-date-picker class="r-w-max" v-model="topIndicatorSearchForm.endTime"
                                            type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                            start-placeholder="开始日期" end-placeholder="结束日期">
                                        </el-date-picker>
                                    </div>
                                </el-form-item>
                                <el-form-item>
                                    <div class="r-flex r-row-right">
                                        <el-button @click="popHide('topIndicatorFormPopShow')">取消</el-button>
                                        <el-button type="primary" @click="topIndicatorSearchConfirm">确认</el-button>
                                    </div>
                                </el-form-item>
                            </el-form>
                        </div>
                        <el-button slot="reference" class="r-mr-10 sx-btn"
                            @click="popOpen('topIndicator', 'topIndicatorFormPopShow')"
                            :class="{ active: topIndicatorSearchActive }" size="mini">
                            <r-icon name="shaixuan" type="task" size="16"></r-icon>
                        </el-button>
                    </el-popover>
                    <div class="top-indicator-btn hover--main" @click="openTopTable"><r-icon name="full-screen"
                            size="24"></r-icon></div>
                </div>
            </div>
            <div class="section-content">
                <stack-line :loader="getTopIndicatorStatistic" :warningValue="warningValue" ref="topLine"></stack-line>
            </div>
        </el-card>
        <top-table ref="topTable" :warningValue="warningValue"></top-table>
        <div class="r-flex r-col-top slide-cover">
            <div class="r-flex-1">
                <el-card class="section r-mt-30" shadow="never">
                    <div class="section-top r-flex r-col-center">
                        <div class="section-title w-auto">部门进度排行：</div>
                        <div class="r-flex r-row-right r-flex-auto-w">
                            <div class="r-pr-20">
                                <el-button-group>
                                    <el-button :type="depType == 1 ? 'primary' : ''" size="mini" round
                                        @click="departmentIndicatorLoad(1)">负责指标</el-button>
                                    <el-button :type="depType == 2 ? 'primary' : ''" size="mini" round
                                        @click="departmentIndicatorLoad(2)">负责任务</el-button>
                                    <el-button :type="depType == 3 ? 'primary' : ''" size="mini" round
                                        @click="departmentIndicatorLoad(3)">协作指标</el-button>
                                    <el-button :type="depType == 4 ? 'primary' : ''" size="mini" round
                                        @click="departmentIndicatorLoad(4)">协作任务</el-button>
                                </el-button-group>
                            </div>
                            <el-popover placement="bottom-end" width="500" trigger="manual" v-model="depFormPopShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">筛选条件</div>
                                    <el-form size="small" label-width="100px" label-position="left">
                                        <el-form-item label="计划开始时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="depSearchForm.startDate"
                                                    type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="计划结束时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="depSearchForm.endDate"
                                                    type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="popHide('depFormPopShow')">取消</el-button>
                                                <el-button type="primary" @click="depSearchConfirm">确认</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <div slot="reference"  class="top-indicator-btn hover--main r-mr-20"  
                                    @click="popOpen('dep', 'depFormPopShow')" 
                                    :class="{ 'main-color': depSearchActive }">
                                    <r-icon name="shaixuan" type="task" size="24"></r-icon>
                                </div>
                            </el-popover>
                            <div class="top-indicator-btn hover--main r-mr-20" @click="openSaveDep"
                                v-if="$has('handle/indicator/statistics-department/edit')">
                                <r-icon name="setting" size="24"></r-icon>
                            </div>
                            <div class="top-indicator-btn hover--main" @click="openDepTable">
                                <r-icon name="full-screen" size="24"></r-icon>
                            </div>
                        </div>
                    </div>
                    <div style="height:500px">
                        <div class="section-content r-w-max"
                            :style="{ height: depOptionDown.yAxis.data.length > 0 ? '300px' : '500px' }" id="dep-main">
                        </div>
                        <div class="section-content r-w-max" style="height:200px" id="dep-main-down"
                            v-show="depOptionDown.yAxis.data.length > 0"></div>
                    </div>
                </el-card>
            </div>
            <div class="r-flex-1 r-ml-30">
                <el-card class="section r-mt-30" shadow="never">
                    <div class="section-top r-flex r-col-center">
                        <div class="section-title w-auto">人员进度排行：</div>
                        <div class="r-flex r-row-right r-flex-auto-w">
                            <div class="r-pr-20">
                                <el-select v-model="type" @change="employeeIndicatorLoad" size="mini">
                                    <el-option label="指标负责人" :value="1"></el-option>
                                    <el-option label="指标分管领导" :value="2"></el-option>
                                    <el-option label="任务负责人" :value="3"></el-option>
                                    <el-option label="指标协作人" :value="4"></el-option>
                                    <el-option label="任务协作人" :value="5"></el-option>
                                </el-select>
                            </div>
                            <el-popover placement="bottom-end" width="500" trigger="manual" v-model="employeeFormPopShow"
                                @click.stop.native>
                                <div>
                                    <div class="r-pb-20">筛选条件</div>
                                    <el-form size="small" label-width="100px" label-position="left">
                                        <el-form-item label="计划开始时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="employeeSearchForm.startDate"
                                                    type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item label="计划结束时间">
                                            <div class="r-flex">
                                                <el-date-picker class="r-w-max" v-model="employeeSearchForm.endDate"
                                                    type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                    start-placeholder="开始日期" end-placeholder="结束日期">
                                                </el-date-picker>
                                            </div>
                                        </el-form-item>
                                        <el-form-item>
                                            <div class="r-flex r-row-right">
                                                <el-button @click="popHide('employeeFormPopShow')">取消</el-button>
                                                <el-button type="primary" @click="employeeSearchConfirm">确认</el-button>
                                            </div>
                                        </el-form-item>
                                    </el-form>
                                </div>
                                <div slot="reference"  class="top-indicator-btn hover--main r-mr-20"  
                                    @click="popOpen('employee', 'employeeFormPopShow')" 
                                    :class="{ 'main-color': employeeSearchActive }">
                                    <r-icon name="shaixuan" type="task" size="24"></r-icon>
                                </div>
                            </el-popover>
                            <div class="top-indicator-btn hover--main" @click="openEmployeeIndicatorTable"><r-icon
                                    name="full-screen" size="24"></r-icon></div>
                        </div>
                    </div>
                    <div class="r-flex r-flex-column" style="height:500px">
                        <div class="section-content r-w-max canvas" id="pp-main"
                            :style="{ height: employeeIndicatorDown.length > 0 ? '300px' : '500px' }"></div>
                        <div class="section-content r-w-max canvas" id="pp-main-down" style="height:200px"
                            v-show="employeeIndicatorDown.length > 0"></div>
                    </div>
                </el-card>
            </div>
        </div>
        <employeeIndicatorTable ref="employeeIndicatorTable" :warningValue="warningValue"></employeeIndicatorTable>
        <depIndicatorTable ref="depIndicatorTable" :warningValue="warningValue"></depIndicatorTable>
        <save-dep ref="saveDep" @change="departmentIndicatorLoad()"></save-dep>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>
<script>
import { indicatorStatistic, taskStatistic, topIndicatorStatistic, employeeIndicatorStatistic, departmentIndicatorStatistic, getProcessWarningValue,editProcessWarningValue } from '@/api/statistics';
import stackLine from "@/components/stack-line/stack-line";
import topTable from "./components/top-table.vue";
import employeeIndicatorTable from "./components/employee-indicator-table.vue"
import depIndicatorTable from "./components/dep-indicator-table.vue"
import saveDep from "./components/save-dep.vue"
import * as echarts from 'echarts';
import { cloneDeep } from '@/utils';
import DepRoleModal from "@/components/dialog/DepRoleModal";
import moment from 'moment';
const indicatorForm = {
    level: '',
    startTime: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
    endTime: ['', ''],
    department: [],
    cooperateDepartment: []
};
const topIndicatorForm = {
    startTime: ['',''],
    endTime: ['', ''],
    department: [],
    cooperateDepartment: []
};
const taskForm = {
    level: '',
    startDate: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
    endDate: ['', ''],
    department: [],
    cooperateDepartment: []
};
const depForm = {
    startDate: [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')],
    endDate: ['', '']
};
export default {
    components: {
        stackLine,
        topTable,
        employeeIndicatorTable,
        saveDep,
        depIndicatorTable,
        DepRoleModal
    },
    data() {
        return {
            //末级指标概览
            indicatorCount: [],
            //末级指标状态
            indicatorStatus: [],
            //未启动指标
            waitIndicator: null,
            //子任务概览
            taskCount: [],
            //子任务状态
            taskStatus: [],
            //未启动任务
            waitTask:null,
            employeeIndicator:[],
            employeeIndicatorLabel:[],
            employeeIndicatorDown:[],
            employeeIndicatorDownLabel:[],
            departmentIndicator:[],
            departmentIndicatorLabel:[],
            departmentIndicatorDown:[],
            departmentIndicatorDownLabel:[],
            progressWidth: 120,
            type: 1,
            depType: 1,
            warningValue: 0,
            employeeIndicatorChart: null,
            employeeDownIndicatorChart: null,
            departmentIndicatorChart: null,
            departmentDownIndicatorChart: null,
            warningValueKey:0,
            saveWarningValueLoading:false,
            modalConfig: {},
            dialogVisible: false,
            //指标筛选
            indicatorForm: Object.assign({}, indicatorForm),
            indicatorSearchForm: Object.assign({}, indicatorForm),
            indicatorFormPopShow: false,
            //子任务筛选
            taskForm: Object.assign({}, taskForm),
            taskSearchForm: Object.assign({}, taskForm),
            taskFormPopShow: false,
            //一级指标
            topIndicatorForm: Object.assign({}, topIndicatorForm),
            topIndicatorSearchForm: Object.assign({}, topIndicatorForm),
            topIndicatorFormPopShow: false,
            //部门进度
            depForm: Object.assign({}, depForm),
            depSearchForm: Object.assign({}, depForm),
            depFormPopShow: false,
            //人员进度排行
            employeeForm: Object.assign({}, depForm),
            employeeSearchForm: Object.assign({}, depForm),
            employeeFormPopShow: false,
        }
    },
    computed: {
        option() {
            let that = this;
            return {
                color: ['#1890FF'],

                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'bold'
                },
                grid: {
                    top: '10%',
                    left: '0%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true,
                    borderWidth: 0,
                    y: 80,
                    y2: 60
                },
                title: [
                    {
                        left: 'center',
                        text: '前五名'
                    },
                ],
                xAxis: {
                    type: 'value',
                    // max:50,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        formatter: '{value} %' // 给每个数值添加%
                    },
                    min: 0,
                    max: 100
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    type: 'category',
                    data: that.employeeIndicatorLabel
                },
                series: [
                    {
                        itemStyle: {
                            color: function (params) {
                                return '#FCCE10';
                            },
                            backgroundStyle: {
                                color: '#EBEEF5'
                            },
                        },
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function (data) {
                                if (data.value < that.warningValue) {
                                    return `{a|${data.value}%}`
                                } else {
                                    return `{b|${data.value}%}`
                                }
                            },
                            rich: {
                                a: {
                                    color: 'red'
                                },
                                b: {
                                    color: 'green'
                                }
                            },
                        },
                        data: that.employeeIndicator,
                        type: 'bar',
                        barWidth: 30 //柱图宽度
                    }
                ]
            }
        },
        optionDown() {
            let that = this;
            return {
                color: ['#1890FF'],
                title: [
                    {
                        left: 'center',
                        text: '后三名'
                    },
                ],
                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'bold'
                },
                grid: {
                    top: '18%',
                    left: '0%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true,
                    borderWidth: 0,
                    y: 80,
                    y2: 60
                },
                xAxis: {
                    type: 'value',
                    // max:50,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        formatter: '{value} %' // 给每个数值添加%
                    },
                    min: 0,
                    max: 100
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    type: 'category',
                    data: that.employeeIndicatorDownLabel
                },
                series: [
                    {
                        itemStyle: {
                            color: function (params) {
                                return '#FCCE10';
                            },
                            backgroundStyle: {
                                color: '#EBEEF5'
                            }
                        },
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function (data) {
                                if (data.value < that.warningValue) {
                                    return `{a|${data.value}%}`
                                } else {
                                    return `{b|${data.value}%}`
                                }
                            },
                            rich: {
                                a: {
                                    color: 'red'
                                },
                                b: {
                                    color: 'green'
                                }
                            },
                        },
                        data: that.employeeIndicatorDown,
                        type: 'bar',
                        barWidth: 30 //柱图宽度
                    }
                ]
            }
        },
        depOption() {
            let that = this;
            return {
                color: ['#1890FF'],

                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'bold'
                },
                title: [
                    {
                        left: 'center',
                        text: '前五名'
                    },
                ],
                grid: {
                    top: '10%',
                    left: '0%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true,
                    borderWidth: 0,
                    y: 80,
                    y2: 60
                },
                xAxis: {
                    type: 'value',
                    // max:50,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        formatter: '{value} %' // 给每个数值添加%
                    },
                    min: 0,
                    max: 100
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    type: 'category',
                    data: that.departmentIndicatorLabel
                },
                series: [
                    {
                        itemStyle: {
                            color: function (params) {
                                return '#1890FF';
                            },
                            backgroundStyle: {
                                color: '#EBEEF5'
                            }
                        },
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function (data) {
                                if (data.value < that.warningValue) {
                                    return `{a|${data.value}%}`
                                } else {
                                    return `{b|${data.value}%}`
                                }
                            },
                            rich: {
                                a: {
                                    color: 'red'
                                },
                                b: {
                                    color: 'green'
                                }
                            },
                        },
                        data: that.departmentIndicator,
                        type: 'bar',
                        barWidth: 30 //柱图宽度
                    }
                ]
            }
        },
        depOptionDown() {
            let that = this;
            return {
                color: ['#1890FF'],

                textStyle: {
                    fontSize: 14,
                    fontStyle: 'normal',
                    fontWeight: 'bold'
                },
                title: [
                    {
                        left: 'center',
                        text: '后三名'
                    },
                ],
                grid: {
                    top: '18%',
                    left: '0%',
                    right: '10%',
                    bottom: '3%',
                    containLabel: true,
                    borderWidth: 0,
                    y: 80,
                    y2: 60
                },
                xAxis: {
                    type: 'value',
                    // max:50,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    axisLabel: {
                        show: true,
                        interval: 0,
                        formatter: '{value} %' // 给每个数值添加%
                    },
                    min: 0,
                    max: 100
                },
                yAxis: {
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: '#fefef',
                        },
                    },
                    type: 'category',
                    data: that.departmentIndicatorDownLabel
                },
                series: [
                    {
                        itemStyle: {
                            color: function (params) {
                                return '#1890FF';
                            },
                            backgroundStyle: {
                                color: '#EBEEF5'
                            }
                        },
                        label: {
                            show: true,
                            position: 'right',
                            formatter: function (data) {
                                if (data.value < that.warningValue) {
                                    return `{a|${data.value}%}`
                                } else {
                                    return `{b|${data.value}%}`
                                }
                            },
                            rich: {
                                a: {
                                    color: 'red'
                                },
                                b: {
                                    color: 'green'
                                }
                            },
                        },
                        data: that.departmentIndicatorDown,
                        type: 'bar',
                        barWidth: 30 //柱图宽度
                    }
                ]
            }
        },
        indicatorSearchActive() {
            return (this.indicatorForm.level && this.indicatorForm.level >= -1) || this.indicatorForm.department.length > 0 || this.indicatorForm.cooperateDepartment.length > 0 || (this.indicatorForm.startTime || []).some(el => el) || (this.indicatorForm.endTime || []).some(el => el);
        },
        topIndicatorSearchActive() {
            return this.topIndicatorForm.department.length > 0 || this.topIndicatorForm.cooperateDepartment.length > 0 || (this.topIndicatorForm.startTime || []).some(el => el) || (this.topIndicatorForm.endTime || []).some(el => el);
        },
        taskSearchActive() {
            return this.taskForm.department.length > 0 || this.taskForm.cooperateDepartment.length > 0 || (this.taskForm.startDate || []).some(el => el) || (this.taskForm.endDate || []).some(el => el);
        },
        depSearchActive() {
            return (this.depForm.startDate || []).some(el => el) || (this.depForm.endDate || []).some(el => el);
        },
        employeeSearchActive() {
            return (this.employeeForm.startDate || []).some(el => el) || (this.employeeForm.endDate || []).some(el => el);
        },
    },
    methods: {
        openSaveDep() {
            this.$refs.saveDep.open();
        },
        getIndicatorStatistic() {
            let formData = this.getIndicatorForm();
            indicatorStatistic({module: 'handle',...formData}).then(res => {
                this.indicatorCount = res.data.count;
                this.indicatorStatus = res.data.status.filter(el => el.prop != 1);
                this.waitIndicator = res.data.status.find(el => el.prop == 1)
            })
        },
        getTaskStatistic() {
            let formData = this.getTaskForm();
            taskStatistic({taskType: 4,module: 'handle',...formData}).then(res => {
                this.taskCount = res.data.count;
                this.taskStatus = res.data.status.filter(el=>el.prop != 'status_not_start');
                this.waitTask = res.data.status.find(el => el.prop == 'status_not_start')
            })
        },
        progressInit() {
            let innerWidth = window.innerWidth;
            if (innerWidth > 1920) {
                this.progressWidth = 120;
            } else {

                this.progressWidth = innerWidth / 1920 * 120;
            }
        },
        getTopIndicatorStatistic(page, pageSize) {
            let formData = this.getTopIndicatorForm();
            return topIndicatorStatistic({ page, pageSize,module: 'handle',...formData }).then(res => {
                return res.data;
            })
        },
        openTopTable() {
            this.$refs.topTable.open(this.topIndicatorForm);
        },
        openEmployeeIndicatorTable() {
            this.$refs.employeeIndicatorTable.open({...this.employeeForm, type: this.type });
        },
        openDepTable() {
            this.$refs.depIndicatorTable.open(
                {
                    type:this.depType,
                    ...this.depForm
                }
            );
        },
        getEmployeeIndicator() {
            return new Promise((res, rej) => {
                //前五后三·
                let options = { type: this.type, page: 1, pageSize: 5, orderBy: 'progress', orderBySort: 'desc', module: 'handle', ...this.employeeForm  };
                if([3,5].includes(this.type)){
                    options.taskType = 4;
                }
                employeeIndicatorStatistic(options).then(_res => {
                    if (_res.data.total > 5) {
                        //获取最后一个页码
                        //拉取数据
                        let _options = cloneDeep(options);
                        _options.orderBySort = 'asc';
                        employeeIndicatorStatistic(_options).then(_res2 => {
                            //截取三位再反转
                            let lastList = _res2.data.list.splice(0, 3).reverse();
                            let list = _res.data.list;
                            list.reverse();
                            res({ list, lastList })
                        })
                    } else {
                        res({ list: _res.data.list.reverse() })
                    }
                })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        getDepartmentIndicator() {
            return new Promise((res, rej) => {
                //前五后三
                let options = { type: this.depType, page: 1, pageSize: 5, orderBy: 'progress', orderBySort: 'desc', module: 'handle',...this.depForm  };
                if([2,4].includes(options.type)){
                    options.taskType = 4;
                }
                departmentIndicatorStatistic(options).then(_res => {
                    if (_res.data.total > 5) {
                        let _options = cloneDeep(options);
                        _options.orderBySort = 'asc';
                        //拉取数据
                        departmentIndicatorStatistic(_options).then(_res2 => {
                            //截取三位再反转
                            let lastList = _res2.data.list.splice(0, 3).reverse();
                            let list = _res.data.list;
                            list.reverse();
                            res({ list, lastList })
                        })
                    } else {
                        res({ list: _res.data.list.reverse() })
                    }
                })
                    .catch(err => {
                        rej(err);
                    })
            })
        },
        employeeIndicatorInit() {
            let chartDom = document.getElementById('pp-main');
            let chartDownDom = document.getElementById('pp-main-down');
            this.employeeIndicatorChart = echarts.init(chartDom);
            this.employeeDownIndicatorChart = echarts.init(chartDownDom);
            this.employeeIndicatorLoad();
        },
        departmentIndicatorInit() {
            let chartDom = document.getElementById('dep-main');
            let chartDownDom = document.getElementById('dep-main-down');
            this.departmentIndicatorChart = echarts.init(chartDom);
            this.departmentDownIndicatorChart = echarts.init(chartDownDom);
            this.departmentIndicatorLoad();
        },
        employeeIndicatorLoad(type) {
            if (type) {
                this.type = type;
            }
            //拉取数据
            this.getEmployeeIndicator().then(({ list, lastList }) => {
                //提取所有
                this.employeeIndicator = list.map(item => parseFloat(item.progress));
                this.employeeIndicatorLabel = list.map(item => item.nickname);
                //需要重绘
                let checkResize = false;
                if (lastList) {
                    //检查是否需要重绘
                    checkResize = this.employeeIndicatorDown.length == 0;
                    //写入数据
                    this.employeeIndicatorDown = lastList.map(item => parseFloat(item.progress));
                    this.employeeIndicatorDownLabel = lastList.map(item => item.nickname);
                } else {
                    checkResize = this.optionDown.series[0]?.data.length > 0;
                    this.employeeIndicatorDown = [];
                    this.employeeIndicatorDownLabel = [];
                }
                this.$nextTick(() => {
                    if (checkResize) {
                        this.employeeDownIndicatorChart && this.employeeDownIndicatorChart.resize();
                        this.employeeIndicatorChart && this.employeeIndicatorChart.resize();
                    }
                    this.option && this.employeeIndicatorChart.setOption(this.option);
                    if (lastList) {
                        this.$nextTick(() => {
                            this.optionDown && this.employeeDownIndicatorChart.setOption(this.optionDown);
                        })
                    }
                })
            })
        },
        departmentIndicatorLoad(type) {
            if (type) {
                this.depType = type;
            }
            //拉取数据
            this.getDepartmentIndicator().then(({ list, lastList }) => {
                //提取所有
                this.departmentIndicator = list.map(item => parseFloat(item.progress));
                this.departmentIndicatorLabel = list.map(item => item.name);
                //需要重绘
                let checkResize = false;
                if (lastList) {
                    //检查是否需要重绘
                    checkResize = this.departmentIndicatorDown.length == 0;
                    this.departmentIndicatorDown = lastList.map(item => parseFloat(item.progress));
                    this.departmentIndicatorDownLabel = lastList.map(item => item.name);
                } else {
                    checkResize = this.depOptionDown.series[0]?.data.length > 0;
                    this.departmentIndicatorDown = [];
                    this.departmentIndicatorDownLabel = [];
                }
                this.$nextTick(() => {
                    if (checkResize) {
                        this.departmentDownIndicatorChart && this.departmentDownIndicatorChart.resize();
                        this.departmentIndicatorChart && this.departmentIndicatorChart.resize();
                    }
                    this.depOption && this.departmentIndicatorChart.setOption(this.depOption);
                    if (lastList) {
                        this.$nextTick(() => {
                            this.depOptionDown && this.departmentDownIndicatorChart.setOption(this.depOptionDown);
                        })
                    }
                })
            })
        },
        echartResize() {
            this.employeeIndicatorChart && this.employeeIndicatorChart.resize();
            this.departmentIndicatorChart && this.departmentIndicatorChart.resize();
            this.departmentDownIndicatorChart && this.departmentDownIndicatorChart.resize();
            this.employeeDownIndicatorChart && this.employeeDownIndicatorChart.resize();
        },
        echartReView(){
            this.employeeIndicatorChart && this.employeeIndicatorChart.setOption(this.option);
            this.departmentIndicatorChart && this.departmentIndicatorChart.setOption(this.depOption);
            this.departmentDownIndicatorChart && this.departmentDownIndicatorChart.setOption(this.depOptionDown);
            this.employeeDownIndicatorChart && this.employeeDownIndicatorChart.setOption(this.optionDown);
        },
        linkPage(url) {
            this.$router.push(url);
        },
        getProcessWarningValue() {
            return getProcessWarningValue({module:'handle'}).then(res => {
                this.warningValue = parseFloat(res.data.value);
            })
        },
        async init() {
            await this.getProcessWarningValue();
            this.getIndicatorStatistic();
            this.getTaskStatistic();
            this.employeeIndicatorInit();
            this.departmentIndicatorInit();
        },
        setWarningValueKey(){
            this.warningValueKey = this.warningValue;
        },
        saveWarningValue(){
            this.saveWarningValueLoading = true;
            editProcessWarningValue({value:this.warningValueKey,module:'handle'}).then(res=>{
                this.warningValue = this.warningValueKey;
                this.$message.success('预警临界设置成功');
                this.$nextTick(()=>{
                    this.echartReView();
                    this.$refs.warningSetting.doClose()
                })
            })
            .finally(()=>{
                this.saveWarningValueLoading = false;
            })
        },
        popHide(key) {
            this[key] = false;
        },
        popOpen(key, popKey) {
            if(this[popKey]){
                this[popKey] = false;
                return;
            }
            this[popKey] = true;
            this[`${key}SearchForm`] = cloneDeep(this[`${key}Form`]);
        },
        searchConfirm(key, popKey) {
            this.popHide(popKey);
            this[`${key}Form`] = cloneDeep(this[`${key}SearchForm`]);
        },
        handleChangeSelect(selectType, title, model, key) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                searchKey: key,
                checkList: this[key][model] || [],
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this[this.modalConfig['searchKey']][this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        popCoverHide() {
            this.indicatorFormPopShow = false;
            this.taskFormPopShow = false;
            this.topIndicatorFormPopShow = false;
            this.depFormPopShow = false;
            this.employeeFormPopShow = false;
        },
        indicatorSearchConfirm() {
            this.popHide('indicatorFormPopShow');
            this.indicatorForm = cloneDeep(this.indicatorSearchForm);
            this.getIndicatorStatistic();
        },
        taskSearchConfirm() {
            this.popHide('taskFormPopShow');
            this.taskForm = cloneDeep(this.taskSearchForm);
            this.getTaskStatistic();
        },
        topIndicatorSearchConfirm() {
            this.popHide('topIndicatorFormPopShow');
            this.topIndicatorForm = cloneDeep(this.topIndicatorSearchForm);
            this.$refs.topLine.refresh(1);
        },
        depSearchConfirm() {
            this.popHide('depFormPopShow');
            this.depForm = cloneDeep(this.depSearchForm);
            this.departmentIndicatorLoad(this.depType);
        },
        employeeSearchConfirm() {
            this.popHide('employeeFormPopShow');
            this.employeeForm = cloneDeep(this.employeeSearchForm);
            this.employeeIndicatorLoad();
        },
        getIndicatorForm(isLink = false) {
            let formData = cloneDeep(this.indicatorForm);
            if (isLink) {
                formData.collaborateDepartment = formData.cooperateDepartment;
            } else {
                formData.cooperateDepartment = formData.cooperateDepartment.map(el => el.id);
                formData.department = formData.department.map(el => el.id);
            }
            return formData;
        },
        getTaskForm(isLink = false) {
            let formData = cloneDeep(this.taskForm);
            if (isLink) {
                formData.collaborateDepartment = formData.cooperateDepartment;
            } else {
                formData.cooperateDepartment = formData.cooperateDepartment.map(el => el.id);
                formData.department = formData.department.map(el => el.id);
            }
            return formData;
        },
        getTopIndicatorForm(isLink = false) {
            let formData = cloneDeep(this.topIndicatorForm);
            if (isLink) {
                formData.collaborateDepartment = formData.cooperateDepartment;
            } else {
                formData.cooperateDepartment = formData.cooperateDepartment.map(el => el.id);
                formData.department = formData.department.map(el => el.id);
            }
            return formData;
        },
    },
    mounted() {
        this.init();
    },
    created() {
        this.progressInit();
        window.addEventListener('resize', this.progressInit);
        window.addEventListener('resize', this.echartResize);
    },
    beforeDestroy() {
        window.removeEventListener('resize', this.progressInit);
        window.removeEventListener('resize', this.echartResize);
    }

}
</script>

<style lang="scss" scoped>
.statistics-wrapper {
    padding-left: 20px;
    position: relative;

    .sx-btn {
        padding: 7px 15px;

        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }
    
    @media screen and (max-width: 1570px) {
        .slide-cover {
            flex-direction: column;

            .r-flex-1 {
                width: 100%;
                margin-left:0;
            }
        }
    }

    .warning-setting {
        position: absolute;
        font-size: 30px;
        left: -16px;
        top: 5px;
        height: 30px;
        line-height: 30px;
        transition: opacity .2s;
        cursor: pointer;

        &:hover {
            opacity: 0.7;
        }
    }

    .section {
        &-search {
            margin-bottom: 20px;

            &-content {
                text-align: right;
            }
        }

        .section-block {
            padding-left: 40px;
            position: relative;

            &-title {
                font-size: 20px;
                width: 40px;
                text-align: center;
                padding: 0 10px;
                background: rgb(174, 174, 174);
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                border-radius: 8px 0 0 8px;

                span {
                    height: 100%;
                    color: #fff;
                }
            }

            .list {
                padding: 10px;
                border: 1px solid #eeeeee;
            }
        }

        .section-top {
            .section-title {
                font-size: 18px;
                width: 130px;

                &.w-auto {
                    width: auto;
                }
            }

            .section-top-extend {
                padding-right: 15vw;

                .section-top-item {
                    p:first-child {
                        font-size: 28px;
                        font-weight: bold;
                        line-height: 1;
                    }

                    p:last-child {
                        font-size: 14px;
                        color: #999999;
                        padding-top: 8px;
                    }
                }
            }
        }

        .section-content {
            padding-top: 30px;

            &.canvas:last-child {
                padding-top: 10px;
            }

            .status-item {
                .status-item-l {
                    p:first-child {
                        font-size: 14px;

                        span:first-child {
                            width: 6.8px;
                            height: 6.8px;
                            border-radius: 50%;
                            margin-right: 5px;
                        }
                    }

                    p:last-child {
                        padding-top: 5px;

                        span:first-child {
                            font-size: 28px;
                            font-weight: bold;
                            line-height: 1.2;
                        }

                        span {
                            vertical-align: bottom;
                            padding-left: 5px;
                        }
                    }
                }
            }
        }

        #main {
            height: 500px;
        }

        .sub-item {
            height: 40px;
            font-size: 13px;

            &-content {
                padding: 0 10px;
                height: 40px;
                line-height: 40px;
                white-space: nowrap;
            }
        }

        .section-cover {
            ::v-deep .el-card__body {
                padding: 0;
            }

            .section-item {
                padding: 10px;
            }
        }
    }
}
</style>