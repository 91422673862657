<template>
    <div class="kpi-wrapper r-page">
        <div class="r-filters">
            <div class="r-search">
                <el-form size="small" inline>
                    <el-form-item label="当前流程人">
                        <r-tag-select v-model="query.currentNodeUserId" :collapseTags="true" placeholder="请选择当前流程人"
                            @focus="handleEmployee('checkbox', '请选择当前流程人', 'currentNodeUserId')" labelKey="name" @remove-tag="search"></r-tag-select>
                    </el-form-item>  
                    <el-form-item label="审批状态">
                        <el-select v-model="query.status" @change="search" clearable>
                            <el-option v-for="item in statusEnum" :key="item.value" :label="item.label" :value="item.value"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-input placeholder="请输入关键词" v-model="query.keyword" @keydown.enter.native="search" clearable>
                            <i slot="suffix" class="el-input__icon el-icon-search hover--opacity" @click="search"></i>
                        </el-input>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="r-content">
            <r-table ref="table" :loader="loader" height="100%" :columns="columns" :cell-class-name="cellClassName"
                @cell-click="detailOpen" row-key="id" @sortChange="sortChange">
                <template v-slot:name="{ row }">
                    <el-link>{{ row.name }}</el-link>
                </template>
                <template v-slot:statusCn="{row}">
                    <span :style="{color:row.statusColor}">{{ row.statusCn }}</span>
                </template>
            </r-table>
        </div>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
        <applyDetail ref="applyDetail" @close="approveUpdate"></applyDetail>
    </div>
</template>
<script>
import { taskList,getTaskStatusEnums } from "@/api/approve";
import DepRoleModal from "@/components/dialog/DepRoleModal";
import applyDetail from "@/components/approve/detail/applyDetailDialog"
export default {
    components: { DepRoleModal,applyDetail },
    data() {
        return {
            columns: [
                {
                    label: '任务名称',
                    prop: 'name',
                    "show-overflow-tooltip": true,
                    "min-width": '250px'
                },
                {
                    label: '负责人',
                    prop: 'person.nickname',
                    "show-overflow-tooltip": true,
                    "min-width": '100px'
                },
                {
                    label: '协作人',
                    prop: 'collaborator',
                    "show-overflow-tooltip": true,
                    "min-width": '100px'
                },
                {
                    label: '计划开始日期',
                    prop: 'startDate',
                    "show-overflow-tooltip": true,
                    "min-width": '130px',
                    sortable: 'custom'
                },
                {
                    label: '计划结束日期',
                    prop: 'endDate',
                    "show-overflow-tooltip": true,
                    "min-width": '130px',
                    sortable: 'custom'
                },
                {
                    label: '提交时间',
                    prop: 'submitTime',
                    "show-overflow-tooltip": true,
                    "min-width": '150px',
                    sortable: 'custom'
                },{
                    label: '任务状态',
                    prop: 'statusCn',
                    "show-overflow-tooltip": true,
                    "min-width": '130px',
                },{
                    label: '当前流程人',
                    prop: 'currentWorkflowUser',
                    "show-overflow-tooltip": true,
                    "min-width": '130px',
                    sortable: 'custom'
                },
                {
                    label: '评分',
                    prop: 'score',
                    "show-overflow-tooltip": true,
                    "min-width": '110px',
                    sortable: 'custom'
                }
            ],
            modalConfig: {},
            dialogVisible: false,
            query: {
                name: '',
                currentNodeUserId:[],
                status:''
            },
            statusEnum:[]
        }
    },
    methods: {
        approveUpdate(isUpdate) {
            if (isUpdate) {
                this.$refs.table.refresh();
            }
        },
        loader(page, pageSize) {
            return taskList({ page, pageSize, ...this.query,taskType:3 }).then(res => {
                return res.data;
            })
        },
        refresh(page) {
            this.$refs.table.refresh(page);
        },
        search() {
            this.$refs.table.refresh(1);
        },
        add() {
            this.$refs.update.open();
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.query[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.query[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleConfirm(val) {
            this.query[this.modalConfig['model']] = val;
            this.dialogVisible = false;

            this.search();
        },
        cellClassName({ row, column }) {
            return column.property == 'name' ? 'hover--opacity' : '';
        },
        detailOpen(row, column) {
            if (column.property != 'name') return;
            this.$refs.applyDetail.open(row.id);
        },
        sortChange(order) {
            this.query = Object.assign(this.query,order);
            this.$nextTick(()=>{
                this.search();
            })
        },
        handleAddApprove({id = ''}) {
            history.pushState({type: 'open'}, '', this.$route.path + '#open');
            this.approveId = 0;
            this.approveDetailShow = true;
        },
        open(){
            this.$refs.approveDetail.handleOpen();
        },
        getTaskStatusEnums(){
            getTaskStatusEnums().then(res=>{
                this.statusEnum = res.data;
            })
        }
    },
    mounted(){
        this.getTaskStatusEnums();
    }


}
</script>

<style lang="scss" scoped>
.kpi-wrapper {}
</style>