<template>
    <div>
        <r-drawer ref="rDialog" size="800px" :dialog-title="isEdit ? '编辑指标' : '新建指标'" @submit="submit" @close="reset">
            <el-form label-position="top" :rules="rules" :model="form" ref="form" size="small" :disabled="loading" v-if="isInit">
                <el-form-item label="上级指标">
                    <el-select v-model="form.pid" filterable remote reserve-keyword placeholder="请输入关键词"
                        :remote-method="remoteMethod" :loading="pidLoading" class="r-w-max" clearable
                        @change="parentChange" :disabled="isEdit">
                        <el-option :label="item.name" :value="item.id" v-for="item in pEnums"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$dispatchIndicatorName['name']" prop="name">
                    <el-input :placeholder="'请输入'+$dispatchIndicatorName['name']" v-model="form.name"></el-input>
                </el-form-item>
                <div class="r-flex">
                    <el-form-item :label="$dispatchIndicatorName['employee']" prop="employee" class="r-flex-1">
                        <r-tag-select v-model="form.employee" labelKey="name" :collapseTags="true" :placeholder="`请选择${$dispatchIndicatorName['employee']}`"
                            max @focus="handleEmployee('radio', `请选择${$dispatchIndicatorName['employee']}`, 'employee')"></r-tag-select>
                    </el-form-item>
                    <el-form-item :label="$dispatchIndicatorName['collaborateEmployee']" class="r-flex-3 r-ml-15" prop="collaborateEmployee">
                        <r-tag-select v-model="form.collaborateEmployee" labelKey="name" :placeholder="`请选择${$dispatchIndicatorName['collaborateEmployee']}`" max
                            @focus="handleEmployee('checkbox', '请选择'+$dispatchIndicatorName['collaborateEmployee'], 'collaborateEmployee')" valueKey="id"
                            :collapseTags="form.collaborateEmployee.length > 6"></r-tag-select>
                    </el-form-item>
                </div>
                <div class="r-flex">
                    <el-form-item :label="$dispatchIndicatorName['department']" prop="department" class="r-flex-1">
                        <r-tag-select v-model="form.department" labelKey="name" :collapseTags="true"
                            :placeholder="`请选择${$dispatchIndicatorName['department']}`" max
                            @focus="handleChangeSelect('checkbox', '请选择'+$dispatchIndicatorName['department'], 'department')"></r-tag-select>
                    </el-form-item>
                    <el-form-item :label="$dispatchIndicatorName['collaborateDepartment']" prop="collaborateDepartment" class="r-flex-3 r-ml-15">
                        <r-tag-select v-model="form.collaborateDepartment" labelKey="name" :placeholder="`请选择${$dispatchIndicatorName['collaborateDepartment']}`" max
                            @focus="handleChangeSelect('checkbox', '请选择'+$dispatchIndicatorName['collaborateDepartment'], 'collaborateDepartment')" valueKey="id"
                            :collapseTags="form.collaborateDepartment.length > 6"></r-tag-select>
                    </el-form-item>
                </div>
                <div class="r-flex">
                    <el-form-item :label="$dispatchIndicatorName['startTime'] + ' - ' +$dispatchIndicatorName['endTime']" prop="date">
                        <el-date-picker v-model="form.date" value-format="yyyy-MM-dd" type="daterange"
                            range-separator="至" :start-placeholder="$dispatchIndicatorName['startTime']" :end-placeholder="$dispatchIndicatorName['endTime']"
                            :picker-options="pickerOptions" :clearable="false">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item :label="$dispatchIndicatorName['leader']" class="r-flex-1 r-ml-15" prop="leader">
                        <r-tag-select v-model="form.leader" labelKey="name" :placeholder="`请选择${$dispatchIndicatorName['leader']}`" max
                            @focus="handleEmployee('radio', '请选择'+$dispatchIndicatorName['leader'], 'leader')" valueKey="id"
                            :collapseTags="true"></r-tag-select>
                    </el-form-item>
                </div>
                <el-form-item :label="$dispatchIndicatorName['sourceTime']" prop="sourceTime">
                    <el-date-picker
                    v-model="form.sourceTime"
                    type="date"
                    value-format='yyyy-MM-dd'
                    :placeholder='`请选择${$dispatchIndicatorName["sourceTime"]}`'>
                    </el-date-picker>
                </el-form-item>
                <el-form-item :label="$dispatchIndicatorName['superviseFile']" prop="superviseFile">
                    <el-input v-model="form.superviseFile" :placeholder="'请输入'+$dispatchIndicatorName['superviseFile']" type="textarea"
                        :autosize="{ minRows: 4, maxRows: 10 }"></el-input>
                </el-form-item>
                <el-form-item :label="$dispatchIndicatorName['sourceUnity']" prop="sourceUnity">
                    <el-input v-model="form.sourceUnity" :placeholder="`请输入${$dispatchIndicatorName['sourceUnity']}`" type="textarea"
                        :autosize="{ minRows: 4, maxRows: 10 }"></el-input>
                </el-form-item>
                <el-form-item :label="$dispatchIndicatorName['no']" prop="no" v-if="form.id">
                    <div class="r-flex">
                        <div class="item-no r-flex" v-for="(item, index) in noLink" :key="index">{{ item
                            }}<span>-</span>
                        </div>
                        <el-input-number :min="1" v-model="form.no" :controls="false"></el-input-number>
                    </div>
                </el-form-item>
                <el-form-item :label="$dispatchIndicatorName['annex']" prop="annex">
                    <multiUploadFile v-model="form.annex" @change="checkFile"></multiUploadFile>
                </el-form-item>  
                <el-form-item label="详情">
                    <edit v-model="form.describe" v-if="isInit" :disabled="loading"></edit>
                </el-form-item>
            </el-form>
        </r-drawer>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>

<script>
import DepRoleModal from "@/components/dialog/DepRoleModal";
import edit from "@/components/edit";
import { add, detail, enums, template } from "@/api/indicator";
import { cloneDeep, dateContrast, getDateRange } from "@/utils";
import multiUploadFile from "@/components/imageUpload/multiUploadFile";
import moment from "moment";
const defaultForm = {
    //指标名称
    name: '',
    //负责人
    employee: [],
    //协作人
    collaborateEmployee: [],
    //负责部门
    department: [],
    //协作部门
    collaborateDepartment: [],
    //起始及结束时间
    date: [],
    //描述
    describe: '',
    pid: '',
    pName: '',
    //分管领导
    leader: [],
    no: 1,
    //附件
    annex: [],
    //督办文件
    superviseFile:'',
    //来文单位
    sourceUnity:'',
    //来文时间
    sourceTime:''
}
export default {
    components: { DepRoleModal, edit, multiUploadFile },
    data() {
        return {
            form: cloneDeep(defaultForm),
            rules: {},
            isEdit: false,
            modalConfig: {},
            employeeModalConfig: {},
            dialogVisible: false,
            employeeDialogVisible: false,
            isInit: false,
            loading: false,
            pidLoading: false,
            pEnums: [],
        }
    },
    computed: {
        pickerOptions() {
            let that = this;
            return {
                disabledDate(time) {
                    let dateRange = that.form?.pDate && that.form?.pDate?.length > 0 ? getDateRange(that.form.pDate[0], that.form.pDate[1]) : '';
                    let date = moment(time).format("YYYY-MM-DD");
                    return dateRange ? !dateRange.includes(date) : false;
                },
            }
        },
        noLink() {
            let link = this.form?.noLink ? cloneDeep(this.form?.noLink) : [];
            //删除最后一位
            if (link.length > 0) {
                link.pop();
                return link;
            } else {
                return [];
            }
        },
        defaultRules(){
           return {
                name: [{ required: true, message: `请填写`, trigger: 'blur' }],
                date: [{ required: true, message: `请选择`, trigger: 'change' }],
                employee: [{ required: true, message: `请选择`, trigger: 'change' }],
                parentDep: [{ required: true, message: `请选择`, trigger: 'change' }],
                collaborateEmployee: [{ required: true, message: `请选择`, trigger: 'change' }],
                department: [{ required: true, message: `请选择`, trigger: 'change' }],
                collaborateDepartment: [{ required: true, message: `请选择`, trigger: 'change' }],
                leader: [{ required: true, message: `请选择`, trigger: 'change' }],
                no: [{ required: true, message: `请填写`, trigger: 'blur' }],
                superviseFile: [{ required: true, message: `请填写`, trigger: 'blur' }],
                sourceUnity: [{ required: true, message: `请填写`, trigger: 'blur' }],
                annex: [{ required: true, message: `请上传`, trigger: 'change' }],
                sourceTime: [{ required: true, message: `请选择`, trigger: 'change' }],
            }
        }
    },
    methods: {
        /**
         * 
         * @param {*} form   需要覆盖的表单
         * @param {*} isEdit 是否为编辑模式
         * @param {*} isGetDetail 是否拉取当前详情，只有编辑模式可用
         * @param {*} success 成功回调
         */
        async open(form = null, isEdit = false, isGetDetail = false, success = null) {
            await this.getRules();
            this.isEdit = isEdit;
            if (form) {
                this.form = Object.assign(this.form, form);
                if (this.form?.pid && this.form.pName) {
                    this.pEnums = [
                        {
                            id: this.form.pid,
                            name: this.form.pName
                        }
                    ]
                }
                if (this.isEdit && this.form.pid) {
                    await this.getParentData();
                }
            }
            if (this.isEdit && this.form.id && isGetDetail) {
                this.getDetail();
            } else {
                this.remoteMethod();
            }
            //填充数据
            if (!this.isEdit && !this.form.pid) {
                this.form.date = [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')]
            }
            this.$refs.rDialog.handleOpen();
            //进行反馈
            success && success();
            
            //触发初始化
            this.$nextTick(() => {
                this.$refs['form']?.clearValidate && this.$refs['form'].clearValidate();
                this.isInit = true;
            })
        },
        getParentData() {
            return detail({ id: this.form.pid,module:'dispatch' }).then(res => {
                this.$set(this.form, 'pDate', [res.data.startTime, res.data.endTime]);
            })
                .catch(err => {
                    console.log(err);
                })
        },
        getDetail() {
            return detail({ id: this.form.id,module:'dispatch' }).then(res => {
                this.form = Object.assign(this.form, {
                    pid: res.data?.pid ? res.data.pid == '00000000-0000-0000-0000-000000000000' ? '' : res.data?.pid : '',
                    pName: res.data.pName,
                    employee: res.data?.employee || [],
                    collaborateEmployee: res.data?.collaborateEmployee || [],
                    department: res.data?.department || [],
                    collaborateDepartment: res.data?.collaborateDepartment || [],
                    date: res.data?.startTime ?  [res.data?.startTime, res.data?.endTime] : [],
                    describe: res.data?.describe || '',
                    name: res.data.name,
                    noLink: res.data?.noLink || [],
                    no: res.data?.no || 1,
                    leader: res.data?.leader || [],
                    sourceUnity:res.data?.sourceUnity  || '',
                    superviseFile:res.data?.superviseFile || '',
                    annex:res.data?.annex ? JSON.parse(res.data?.annex) : [],
                    sourceTime:res.data?.sourceTime || ''
                })
                this.pEnums = res.data?.parentList || [];
            })
                .catch(err => {
                    console.log(err);
                })
        },
        submit(done) {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    if (!this.formCheck()) {
                        done(false);
                        return;
                    }
                    let form = cloneDeep(this.form);
                    form['startDate'] = form.date[0];
                    form['endDate'] = form.date[1];
                    form['annex'] = form.annex.length>0 ? JSON.stringify(form.annex) : '';
                    this.loading = true;
                    add({...form, module:'dispatch' }).then(res => {
                        done(true);
                        this.$message.success(`指标${this.isEdit ? '编辑' : '新建'}成功`);
                        this.$emit('success', this.isEdit);
                    }).catch(err => {
                        done(false);
                    })
                        .finally(() => {
                            this.loading = false;
                        })
                } else {
                    done();
                }
            });
        },
        formCheck() {
            //判断区间及部门
            let checkDep = this.form.collaborateDepartment.some(el => this.form['department'].find(_el=>el.id == _el.id));
            let checkEmployee = this.form.collaborateEmployee.some(el => el.id == this.form['employee'][0]?.id);
            let pDate = this.form?.pDate || '';

            if (checkEmployee) {
                this.$message.error('协作人不能包含负责人');
                return false;
            }
            if (checkDep) {
                this.$message.error('协作部门不能包含负责部门');
                return false;
            }
            if (pDate) {
                if (dateContrast(this.form.date[0], pDate[0])) {
                    this.$message.error(`子指标的计划开始日期不得早于 ${pDate[0]}`);
                    return false;
                }
                if (dateContrast(pDate[1], this.form.date[1])) {
                    this.$message.error(`子指标的计划结束日期不得晚于 ${pDate[1]}`);
                    return false;
                }
            }
            return true;
        },
        reset() {
            this.form = cloneDeep(defaultForm);
            this.isEdit = false;
            this.isInit = false;
            this.loading = false;
            this.pidLoading = false;
            this.rules = [];
        },
        handleConfirm(val) {
            this.form[this.modalConfig['model']] = val;
            if(this.modalConfig['model'] == 'employee'){
                this.setDep('employee',val[0]);
            }else if(this.modalConfig['model'] == 'collaborateEmployee'){
                this.setDep('collaborateEmployee',val);
            }
            this.dialogVisible = false;
        },
        /**
         * @param String type 协作人还是负责人 传key
         */
        setDep(type,item){
            //负责人
            if(type == 'employee'){
                //存在负责部门
                if(item.depId && !this.form.department.some(el=>el.id == item.depId)){
                    this.form.department.push({id:item.depId,name:item.depName,type:1});
                }
            }else if(type == 'collaborateEmployee'){
                item.forEach(el=>{
                    //存在负责部门
                    if(el.depId && !this.form.collaborateDepartment.some(_el=>_el.id == el.depId)){
                        this.form.collaborateDepartment.push({id:el.depId,name:el.depName,type:1});
                    }
                })
            }
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
            };
            this.dialogVisible = true;
        },
        handleEmployee(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        remoteMethod(name) {
            this.pidLoading = true;
            enums({ name, id: this.form?.id || '', page: 1, pageSize: 20,module:'dispatch' }).then(res => {
                this.pEnums = res.data.list;
            })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    this.pidLoading = false;
                })
        },
        parentChange(val) {
            let item = this.pEnums.find(el => val == el.id);
            if (item) {
                this.form.date = [item.startTime, item.endTime];
                this.$set(this.form, 'pDate', [item.startTime, item.endTime]);
            } else {
                this.$set(this.form, 'pDate', []);
                this.form.date = [moment().startOf('year').format('YYYY-MM-DD'), moment().endOf('year').format('YYYY-MM-DD')]
            }
        },
        //获取规则
        getRules() {
            return template({module:'dispatch'}).then(res => {
                let rules = {};
                //设置别名
                this.$store.commit('setDispatchIndicatorName',res.data);
                //拉取必填的规则
                let list = res.data.filter(el => {
                    return el.require;
                }).map(el => {
                    return {
                        prop: el.prop,
                        rule: this.rules[el.prop]
                    }
                });
                list.forEach(el => {
                    if (el.prop == "startTime" || el.prop == "endTime") {
                        rules['date'] = cloneDeep(this.defaultRules['date']);
                        rules['date'][0].message = rules['date'][0].message + this.$dispatchIndicatorName['date'];
                    } else if (this.defaultRules[el.prop]) {
                        rules[el.prop] = cloneDeep(this.defaultRules[el.prop]);
                        rules[el.prop][0].message = rules[el.prop][0].message + this.$dispatchIndicatorName[el.prop];
                    }
                });
                this.rules = rules;
            })
        },
        checkFile(){
            this.$refs.form.validateField('annex')
        }
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    font-size: 16px;
    padding-bottom: 5px;
}

::v-deep .item-no {
    font-size: 16px;
    color: var(--primary);

    span {
        padding: 0 5px;
    }
}
</style>