<template>
    <div>
        <r-drawer ref="rDialog" size="800px" dialog-title="批量修改任务基本信息" @submit="submit" @close="reset"
            :is-scroll="false">
            <div class="r-h-max r-flex r-flex-column">
                <div class="r-flex r-w-max">
                    <div class="r-flex-1">
                        <el-select v-model="field" size="small" class="r-w-max r-pr-10" @change="fieldChange">
                            <el-option label="负责人" value="personId"></el-option>
                            <el-option label="协作人" value="collaborator"></el-option>
                            <el-option label="负责部门" value="personDepId"></el-option>
                            <el-option label="协作部门" value="collaborationDep"></el-option>
                            <el-option label="逾期空间" value="overdueValidDay"></el-option>
                            <el-option label="审核人" value="taskReviewer"></el-option>
                            <el-option label="评分人" value="taskScorePerson"></el-option>
                            <el-option label="分管领导" value="taskLeader"></el-option>
                            <el-option label="权重" value="weight"></el-option>
                        </el-select>
                    </div>
                    <div class="r-flex-2">
                        <el-form :disabled="loading">
                            <r-tag-select size="small" v-model="form.personId" v-if="field == 'personId'" labelKey="name"
                            placeholder="请选择负责人" max @focus="handleEmployee('radio', '请选择负责人', 'personId')"
                            valueKey="id"></r-tag-select>
                            <r-tag-select size="small" v-model="form.taskReviewer" v-if="field == 'taskReviewer'" labelKey="name"
                            placeholder="请选择审核人" max @focus="handleEmployee('radio', '请选择审核人', 'taskReviewer')"
                            valueKey="id"></r-tag-select>   
                            <div class="r-flex" v-if="field == 'taskScorePerson'">
                                <r-tag-select size="small" v-model="form.taskScorePerson"  labelKey="name"
                                placeholder="请选择评分人" max @focus="handleEmployee('radio', '请选择评分人', 'taskScorePerson')"
                                valueKey="id" :disabled="isAutoScore"></r-tag-select>
                                <el-checkbox :value="isAutoScore" @input="isAutoScoreChange" class="r-ml-10">自动评分</el-checkbox>
                            </div>
                            <r-tag-select size="small" v-model="form.taskLeader" v-if="field == 'taskLeader'" labelKey="name"
                            placeholder="请选择分管领导" max @focus="handleEmployee('radio', '请选择分管领导', 'taskLeader')"
                            valueKey="id"></r-tag-select>
                            <r-tag-select size="small" v-model="form.collaborator" v-if="field == 'collaborator'"
                                labelKey="name" placeholder="请选择协作人" max
                                @focus="handleEmployee('checkbox', '请选择协作人', 'collaborator')" valueKey="id"
                                :collapseTags="form.collaborator && form.collaborator.length > 4"></r-tag-select>
                            <r-tag-select size="small" v-model="form.personDepId" v-if="field == 'personDepId'"
                                labelKey="name" :collapseTags="true" placeholder="请选择负责部门" max
                                @focus="handleChangeSelect('checkbox', '请选择负责部门', 'personDepId')"></r-tag-select>
                            <r-tag-select size="small" v-model="form.collaborationDep" v-if="field == 'collaborationDep'"
                                labelKey="name" placeholder="请选择协作部门" max
                                @focus="handleChangeSelect('checkbox', '请选择协作部门', 'collaborationDep')" valueKey="id"
                                :collapseTags="form.collaborationDep && form.collaborationDep.length > 4"></r-tag-select>
                            <el-input-number size="small" placeholder="请输入逾期空间" v-if="field == 'overdueValidDay'"
                                class="r-w-max" v-model="form.overdueValidDay" :controls="false" :min="0"></el-input-number>
                            <el-input-number size="small" placeholder="请输入逾期空间" v-if="field == 'weight'"
                                class="r-w-max" v-model="form.weight" :controls="false" :min="0"></el-input-number>
                        </el-form>
                    </div>
                </div>
                <div class="r-flex-auto-h r-w-max r-pt-20">
                    <r-table :data="selected" :columns="columns" height="100%">
                        <template v-slot:handle="{ row }">
                            <el-popconfirm title="确定要取消该任务的修改吗？" @confirm="delTask(row)" :disabled="loading">
                            <el-link type="primary" slot="reference" v-if="$has('dispatch-indicator-task-delete')" :disabled="loading">取消</el-link>
                            </el-popconfirm>
                        </template>
                    </r-table>
                </div>
            </div>
        </r-drawer>

        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>

<script>
import { cloneDeep } from "@/utils";
import {batchEditField} from "@/api/approve"
import DepRoleModal from "@/components/dialog/DepRoleModal";
const defaultForm = {
    personId: [],
    collaborator: [],
    personDepId: [],
    collaborationDep: [],
    overdueValidDay: 0,
    taskLeader:[],
    taskScorePerson:[],
    taskReviewer:[],
    weight:0
}
export default {
    components: { DepRoleModal },
    data() {
        return {
            form: cloneDeep(defaultForm),
            modalConfig: {},
            dialogVisible: false,
            loading: false,
            field: 'personId',
            columns: [
                {
                    label: '任务名称',
                    prop: 'name',
                    "show-overflow-tooltip": true,
                    "min-width": '250px'
                },
                {
                    label: '负责人',
                    prop: 'employee',
                    "show-overflow-tooltip": true,
                    "min-width": '100px'
                },
                {
                    label: '所属指标',
                    prop: 'parentList',
                    "min-width": '120px'
                },
                { 'width': '80px', label: '操作', prop: 'handle' }
            ],
            selected:[],
            fieldLabel:{
                personId:'负责人',
                collaborator:'协作人',
                personDepId:'负责部门',
                collaborationDep:'协作部门',
            }
        }
    },
    methods: {
        open(selected) {
            this.selected = cloneDeep(selected);
            this.$refs.rDialog.handleOpen();
        },
        submit(done) {
            if(this.field != 'overdueValidDay' && this.form[this.field].length == 0){
                this.$message.error(`请选择${this.fieldLabel[this.field]}`);
                done(false);
                return;
            }
            this.loading = true;
            this.$confirm(`确定要批量修改当前选中的${this.selected.length}条任务数据吗？`, '提示', { type: 'warning' }).then(
                () => {
                    let form = this.getFormData();
                    batchEditField({info:form}).then(res => {
                        this.$message.success(`已成功批量${this.selected.length}条任务数据`);
                        this.$emit('success');
                        done(true);
                        //替换
                        this.form = cloneDeep(defaultForm);
                    }).catch(err => {
                        done(false);
                    })
                    .finally(() => {
                        this.loading=false;
                    })
                }
            )
        },
        reset() {
            this.form = cloneDeep(defaultForm);
            this.modalConfig = {};
            this.loading = false;
            this.selected = [];
        },
        handleConfirm(val) {
            this.form[this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        handleEmployee(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
                isStaff: 1,
                onlyStaff: 1
            };
            this.dialogVisible = true;
        },
        handleChangeSelect(selectType, title, model) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                checkList: this.form[model] || [],
            };
            this.dialogVisible = true;
        },
        delTask(row){
            this.selected = this.selected.filter(el=>el.id!==row.id);
        },
        //切换的时候重置
        fieldChange(){
            this.form = cloneDeep(defaultForm);
        },
        //生成提交数据
        getFormData(){
            let form = [];
            this.selected.forEach(el=>{
                form.push({
                    taskId:el.id,
                    field:this.field,
                    value:this.field == 'personId' ? this.form[this.field][0]?.id : ['overdueValidDay','weight'].includes(this.field) ? this.form[this.field] : this.form[this.field].map(el=>{
                        return {
                            value:el.id,
                            label:el.nickname || el.name
                        }
                    })
                })
            });

            return form;
        },
        isAutoScoreChange(val) {
            if (val) {
                this.form.taskScorePerson = [{
                "id": "00000000-0000-0000-0000-000000000000",
                "userId": "00000000-0000-0000-0000-000000000000",
                "name": "自动评分",
                "parentId": "00000000-0000-0000-0000-000000000000",
                "employeeId": "00000000-0000-0000-0000-000000000000",
                "type": 2,
                "avatar": ""
                }];
            } else {
                this.form.taskScorePerson = [];
            }
        }
    },
    computed:{
        //是否为自动评分
        isAutoScore() {
        return this.form.taskScorePerson?.length > 0 && this.form.taskScorePerson[0].id == '00000000-0000-0000-0000-000000000000';
        }
    }


}
</script>

<style lang="scss" scoped>
::v-deep .el-form-item__label {
    font-size: 16px;
    padding-bottom: 5px;
}
</style>