<template>
    <div class="indicator-detail-sub r-flex r-flex-column">
        <div class="r-flex r-row-right r-w-max r-pb-10">
            <el-button type="primary" icon="el-icon-plus" size="small" @click="addIndicator" v-if="$has('dispatch-indicator-create')">新增子指标</el-button>
        </div>
        <div class="r-flex-auto-h r-w-max">
            <r-table ref="table" :loader="loader" height="100%" row-key="id" @sortChange="sortChange"
                @cell-click="detailOpen" :cell-class-name="cellClassName" :columnsProps="{ filter: columnsFilter }"
                :columnLoader="columnLoader">
                <template v-slot:name="{ row }">
                    <el-link>{{ row.name }}</el-link>
                </template>
                <template v-slot:annex="{ row }">
                    <div class="file-list r-flex r-flex-column r-col-top" v-if="row.annex">
                        <el-link type="primary" v-for="(item, index) in JSON.parse(row.annex)" :key="index"
                            :href="$getStaticUrl(item.url)" target="_blank">{{ item.name }}</el-link>
                    </div>
                </template>
                <template v-slot:no="{ row }">
                    <span>{{ row | noFilter }}</span>
                </template>
                <template v-slot:status="{row}">
                    <p v-if="row.statusStr" :style="`color:${row.statusColor};`">
                       <r-icon name="share" :color="row.statusColor" size="16" v-if="row.statusEdit"></r-icon>
                       <span :class="{'r-pl-5':row.statusEdit}">{{ row.statusStr }}</span> 
                    </p>
                    <p v-else>--</p>
                </template>
            </r-table>
        </div>
        <update-indicator @success="refresh(0)" ref="updateIndicator"></update-indicator>
    </div>
</template>
<script>
import { list, template, weightSave } from "@/api/indicator";
import updateIndicator from "../update";
import { columns } from "@/config/table";
export default {
    components: { updateIndicator },
    props: {
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    computed: {
        columnsConfig() {
            return columns['indicator'];
        }
    },
    data() {
        return {
            query: {
                orderBy: '',
                orderBySort: ''
            }
        }
    },
    filters: {
        noFilter(row) {
            return row?.noLink?.length > 0 ? row.noLink.join('.') : ''
        }
    },
    methods: {
        loader(page, pageSize) {
            return list({ page, pageSize, pid: this.data.id, ...this.query }).then(res => {
                return res.data;
            })
        },
        columnLoader() {
            return template({module:'dispatch'}).then(res => {
                //设置别名
                this.$store.commit('setDispatchIndicatorName', res.data);
                return this.columnsFilter(res.data);
            })
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.$refs.table.refresh(1);
            })
        },
        detailOpen(row, column) {
            if (column.property == 'name') {
                this.$emit('openDetail',row.id);
            } else if (column.property == 'no') {
                this.$refs.table.toggleRowExpansion(row);
            } else if(column.property == 'weight'){
                this.$prompt('请输入一个范围为0~100的整数', '权重', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputValue:row.weight,
                    inputValidator:(val)=>{
                        let numReg = /^[0-9]+$/g;
                        if(!numReg.test(val)){
                            return '权重只能是整数'
                        }
                        if(parseInt(val) < 0 || parseInt(val) > 100){
                            return '权重范围为0~100'
                        }
                        return true;
                    },
                    beforeClose: (action, instance, done) => {
                        if(action === 'confirm'){
                            if(row.weight === parseInt(instance.inputValue)){
                                this.$message.success('权重未发生更改');
                                done();
                                return;
                            }
                            weightSave({
                                id: row.id,
                                weight: parseInt(instance.inputValue)
                            }).then(() => {
                                this.$message.success('权重修改成功');
                                this.refresh();
                                done();
                            }).catch(() => {})
                        }else{
                            done();
                        }
                    }
                }).then(({ value }) => {}).catch(() => {});
            }
        },
        cellClassName({ row, column }) {
            if (column.property == 'name' && !row.isEdit) {
                return `hover--opacity table-expend`;
            } else if (column.property == 'no') {
                return 'hover--opacity no-switch'
            } else if (column.property == 'weight'){
                return 'hover--opacity'
            }else {
                return '';
            }
        },
        refresh(page = '') {
            this.$refs.table.refresh(page);
        },
        addIndicator() {
            this.$refs.updateIndicator.open({
                pid: this.data.id,
                pName: this.data.name,
                pDate: [this.data?.startTime, this.data?.endTime],
                date: [this.data?.startTime, this.data?.endTime],
                leader: this.data.leader || [],
                employee: this.data.employee || []
            }, false);
        },
        //过滤方法
        columnsFilter(columns) {
            let _columns = columns.filter(el => el.show).map(el => {
                return {
                    label: el.alias || el.label,
                    prop: el.prop,
                    ...this.columnsConfig[el.prop]
                }
            })
            return _columns;
        },
    }


}
</script>

<style lang="scss" scoped>
.indicator-detail-sub {
    height: 100%;
}
</style>