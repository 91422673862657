<template>
<div class="indicator-detail-des r-flex r-flex-column">
    <div class="indicator-detail-des__info r-flex r-flex-wrap">
        <div class="indicator-detail-des__info-item r-flex">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['employee'] }} / {{ $handleIndicatorName['collaborateEmployee'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ employee }} <span v-if="collaborateEmployee">/</span> {{ collaborateEmployee }}</div>
        </div> 
        <div class="indicator-detail-des__info-item r-flex">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['startTime'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ startTime }}</div>
        </div>     
        <div class="indicator-detail-des__info-item r-flex">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['endTime'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ endTime }}</div>
        </div>    
        <div class="indicator-detail-des__info-item r-flex" v-if="superviseFile">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['superviseFile'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ superviseFile }}</div>
        </div>    
        <div class="indicator-detail-des__info-item r-flex" v-if="sourceUnity">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['sourceUnity'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ sourceUnity }}</div>
        </div>  
        <div class="indicator-detail-des__info-item r-flex" v-if="sourceTime">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['sourceTime'] }}</div>
            <div class="indicator-detail-des__info-item--value">{{ sourceTime }}</div>
        </div>
        <div class="indicator-detail-des__info-item r-flex r-w-max r-col-top" v-if="annex.length>0">
            <div class="indicator-detail-des__info-item--label">{{ $handleIndicatorName['annex'] }}</div>
            <div class="indicator-detail-des__info-item--value r-flex r-flex-wrap">
                <div v-for="(item,index) in annex" :key="index">
                    <el-link type="primary" icon="el-icon-document" :href="$getStaticUrl(item.url)" target="_blank">{{item.name}}</el-link>
                    <el-divider direction="vertical" v-if="index !== annex.length - 1"></el-divider>
                </div>
            </div>
        </div>
    </div>
    <div class="indicator-detail-des__view r-flex-auto-h scroll-view" v-html="content"></div>
</div>
</template>
<script>

export default {
    props:{
        data:{
            type:Object,
            default(){
                return {};
            }
        }
    },
    computed:{
        startTime(){
            return this.data?.startTime || '';
        },
        endTime(){
            return this.data?.endTime || '';
        },
        employee(){
            return this.data?.employee ? this.data?.employee[0]?.name : ''
        },
        collaborateEmployee(){
            return this.data?.collaborateEmployee ? this.data?.collaborateEmployee.map(el=>el.name).join(' . ') : ''
        },
        content(){
            return this.data?.describe || '';
        },
        sourceUnity(){
            return this.data?.sourceUnity || '';
        },
        superviseFile(){
            return this.data?.superviseFile || '';
        },
        sourceTime(){
            return this.data?.sourceTime || '';
        },
        annex(){
            return this.data?.annex ? JSON.parse(this.data?.annex) : [];
        }
    }

}
</script>

<style lang="scss" scoped>
.indicator-detail-des{
    height:100%;
    &__info{
        width: 100%;
        &-item{
            margin-bottom:20px;
            margin-right:10vw;
            &:last-child{
                margin-right:0;
            }
            &--label{
                color:#999999;
                padding-right:20px;
            }
            &--value{
                color:#666666;
                white-space: pre-line;
            }
        }
    }

    &__view{
        border:1px solid #e5e5e5;
        width: 100%;
        padding: 15px;
        border-radius: 6px;
        ::v-deep img,::v-deep video{
            max-width:100%;
            height:auto;
        }
        ::v-deep p{
            font-size:14px;
            line-height:24px;
        }
    }

}
</style>