import { render, staticRenderFns } from "./dep-indicator-table.vue?vue&type=template&id=4284a413&scoped=true"
import script from "./dep-indicator-table.vue?vue&type=script&lang=js"
export * from "./dep-indicator-table.vue?vue&type=script&lang=js"
import style0 from "./dep-indicator-table.vue?vue&type=style&index=0&id=4284a413&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4284a413",
  null
  
)

export default component.exports