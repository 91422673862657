/**
 * 日常工作督办
 */

import dispatchIndicator from "@/views/dispatch/indicator";
import dispatchIndicatorTaskList from "@/views/dispatch/task/list";
import dispatchIndicatorTask from "@/views/dispatch/task/index";
import dispatchIndicatorStatistics from "@/views/dispatch/statistics/index";
export default {
    dispatchIndicator,
    dispatchIndicatorTaskList,
    dispatchIndicatorTask,
    dispatchIndicatorStatistics
}
  