<template>
    <div>
        <r-drawer ref="rDialog" size="100%" direction="btt" dialog-title="一级指标统计" :footer="false" @close="closed">
            <template v-slot:title>
                <div class="r-flex r-row-bet r-col-bottom">
                    <p class="r-drawer-title">一级指标统计</p>
                    <div class="r-flex">
                        <el-popover placement="bottom-end" width="500" trigger="manual"
                            v-model="show" @click.stop.native>
                            <div>
                                <div class="r-pb-20">筛选条件</div>
                                <el-form size="small" label-width="100px" label-position="left">
                                    <el-form-item label="负责部门">
                                        <r-tag-select v-model="searchQuery.department" class="r-w-max"
                                            :collapseTags="true" placeholder="请选择部门"
                                            @focus="handleChangeSelect('checkbox', '请选择负责部门', 'department', 'searchQuery')"
                                            labelKey="name"></r-tag-select>
                                    </el-form-item>
                                    <el-form-item label="协作部门">
                                        <r-tag-select v-model="searchQuery.cooperateDepartment"
                                            class="r-w-max" :collapseTags="true" placeholder="请选择协作部门"
                                            @focus="handleChangeSelect('checkbox', '请选择协作部门', 'cooperateDepartment', 'searchQuery')"
                                            labelKey="name"></r-tag-select>
                                    </el-form-item>
                                    <el-form-item label="计划开始时间">
                                        <div class="r-flex">
                                            <el-date-picker class="r-w-max" v-model="searchQuery.startTime"
                                                type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                start-placeholder="开始日期" end-placeholder="结束日期">
                                            </el-date-picker>
                                        </div>
                                    </el-form-item>
                                    <el-form-item label="计划结束时间">
                                        <div class="r-flex">
                                            <el-date-picker class="r-w-max" v-model="searchQuery.endTime"
                                                type="daterange" range-separator="至" value-format="yyyy-MM-dd"
                                                start-placeholder="开始日期" end-placeholder="结束日期">
                                            </el-date-picker>
                                        </div>
                                    </el-form-item>
                                    <el-form-item>
                                        <div class="r-flex r-row-right">
                                            <el-button @click="popHide">取消</el-button>
                                            <el-button type="primary" @click="searchConfirm">确认</el-button>
                                        </div>
                                    </el-form-item>
                                </el-form>
                            </div>
                            <el-button slot="reference" class="r-mr-10 sx-btn"
                                @click="popOpen"
                                :class="{ active: searchActive }" size="mini">
                                <r-icon name="shaixuan" type="task" size="14"></r-icon>
                            </el-button>
                        </el-popover>
                        <el-button size="mini" type="primary" style="margin-right: 10px;"
                            @click="exportData()">导出</el-button>
                    </div>
                </div>
            </template>
            <div class="top-table-container">
                <r-table ref="table" :loader="loader" :columns="columns" height="100%" row-key="id"
                    @sortChange="sortChange">
                    <template v-slot:statusRatio1="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio2="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio3="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio4="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:statusRatio5="{ row, column }">
                        {{ row[column.prop] }}%
                    </template>
                    <template v-slot:progress="{ row, column }">
                        <span :style="{ color: parseFloat(row[column.prop]) >= warningValue ? 'green' : 'red' }">{{
                            row[column.prop] }}</span>
                    </template>
                </r-table>
            </div>
        </r-drawer>
        <dep-role-modal :config="modalConfig" v-model="dialogVisible" @confirm="handleConfirm"></dep-role-modal>
    </div>
</template>
<script>
import { topIndicatorStatistic } from '@/api/statistics';
import fileDownload from "js-file-download";
import { cloneDeep } from "@/utils";
import DepRoleModal from "@/components/dialog/DepRoleModal";
const topIndicatorForm = {
    startTime: ['', ''],
    endTime: ['', ''],
    department: [],
    cooperateDepartment: []
};
export default {
    components:{
        DepRoleModal
    },
    props: {
        warningValue: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            columns: [
                {
                    label: '一级指标',
                    prop: 'name',
                    sortable: 'custom',
                    "min-width": '200px',
                    "show-overflow-tooltip": true
                }, {
                    label: '任务指标总数',
                    prop: 'totalCount',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '未启动',
                    prop: 'status1',
                    sortable: 'custom',
                    'min-width': "120px"
                }, {
                    label: '未启动占比',
                    prop: 'statusRatio1',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '正常进行',
                    prop: 'status2',
                    sortable: 'custom',
                    'min-width': "120px"
                }, {
                    label: '正常进行占比',
                    prop: 'statusRatio2',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '进行缓慢',
                    prop: 'status3',
                    sortable: 'custom',
                    'min-width': "120px"
                }, {
                    label: '进行缓慢占比',
                    prop: 'statusRatio3',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '已完成',
                    prop: 'status4',
                    sortable: 'custom',
                    'min-width': "120px"
                }, {
                    label: '已完成占比',
                    prop: 'statusRatio4',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '未完成',
                    prop: 'status5',
                    sortable: 'custom',
                    'min-width': "120px"
                }, {
                    label: '未完成占比',
                    prop: 'statusRatio5',
                    sortable: 'custom',
                    'min-width': "140px"
                }, {
                    label: '综合进度',
                    prop: 'progress',
                    sortable: 'custom',
                    'min-width': "120px"
                },
            ],
            query: Object.assign({}, topIndicatorForm),
            show:false,
            searchQuery:Object.assign({}, topIndicatorForm),
            modalConfig:{},
            dialogVisible:false
        }
    },
    computed:{
        searchActive() {
            return this.query.department.length > 0 || this.query.cooperateDepartment.length > 0 || (this.query.startTime || []).some(el => el) || (this.query.endTime || []).some(el => el);
        },
    },
    methods: {
        exportData() {
            let formData = this.getFormData();
            topIndicatorStatistic({ page: 1, pageSize: 9999999, ...formData, export: 1,module:'dispatch' }).then(res => {
                fileDownload(res, "一级指标统计.xlsx");
            })
        },
        closed() {
            this.query = Object.assign({}, topIndicatorForm);
            this.show = false;
        },
        open(form = null) {
            if (form) {
                this.query = Object.assign(this.query, cloneDeep(form));
            }
            this.$refs.rDialog.handleOpen();
        },
        search() {
            this.$refs.table.refresh(1);
        },
        sortChange(order) {
            this.query = Object.assign(this.query, order);
            this.$nextTick(() => {
                this.search();
            })
        },
        loader(page, pageSize) {
            let formData = this.getFormData();
            return topIndicatorStatistic({ page, pageSize, ...formData,module:'dispatch' }).then(res => {
                return res.data;
            })
        },
        getFormData() {
            let formData = cloneDeep(this.query);
            formData.cooperateDepartment = formData.cooperateDepartment.map(el => el.id);
            formData.department = formData.department.map(el => el.id);
            return formData;
        },
        popOpen(){
            if(this.show){
                this.show = false;
                return;
            }
            this.searchQuery = cloneDeep(this.query);
            this.show = true;
        },
        popHide(){
            this.show = false;
        },  
        searchConfirm(){
            this.query = cloneDeep(this.searchQuery);
            this.show = false;
            this.search();
        },
        handleConfirm(val) {
            this[this.modalConfig['searchKey']][this.modalConfig['model']] = val;
            this.dialogVisible = false;
        },
        handleChangeSelect(selectType, title, model, key) {
            this.modalConfig = {
                type: 1,
                selectType,
                title,
                checkStrictly: true,
                model,
                searchKey: key,
                checkList: this[key][model] || [],
            };
            this.dialogVisible = true;
        },
    }


}
</script>

<style lang="scss" scoped>
.top-table-container {
    height: 100%;
}

.sx-btn {
        &.active {
            border-color: var(--primary);
            color: var(--primary);
        }
    }
</style>