<template>
  <div class="form-generator-container">
    <div class="left-board">
      <div class="logo-wrapper">
        <i class="el-icon-warning-outline"></i>
        <span>温馨提示，该表单展现方式在客户端将会重写，此处仅设定逻辑</span>
      </div>
      <div class="left-board-cover">
        <div class="transition-cover" :style="transitionStyle">
          <div class="component-group-item">
            <el-scrollbar class="left-scrollbar">
              <div class="components-list">
                <!-- 编辑任务的时候不显示输入控件及系统控件 -->
                <div v-for="(item, listIndex) in leftComponents" :key="listIndex" v-if="!isTask && ((isApprove && item.type != 'TYPE_SYSTEM') || !isApprove)">
                  <div class="components-title">
                    <svg-icon icon-class="component" />
                    {{ item.title }}
                  </div>
                  <draggable class="components-draggable" :list="item.list"
                    :group="{ name: 'componentsGroup', pull: 'clone', put: false }" :clone="cloneComponent"
                    draggable=".components-item" :sort="false" @end="onEnd" filter=".disabled">
                    <div v-for="(element, index) in item.list" :key="index" class="components-item"
                      :class="{ disabled: (drawingFilter.includes(element.__config__?.tagIcon) && element.__config__?.type == 'TYPE_SYSTEM') || (drawingFilter.includes(element.__config__?.tagIcon) && element.__config__?.field == 'village' && isApprove)}"
                      @click="addComponent(element, (drawingFilter.includes(element.__config__?.tagIcon) && element.__config__?.type == 'TYPE_SYSTEM') || (drawingFilter.includes(element.__config__?.tagIcon) && element.__config__?.field == 'village' && isApprove))"
                      v-if="isApprove ? element.__config__.tagIcon != 'divider' : (!isFilings && element.__config__.tagIcon != 'filingsStatus') || isFilings">
                      <div class="components-body r-flex r-row-bet">
                        <div>
                          <svg-icon :icon-class="element.__config__.tagIcon" />
                          {{ element.__config__.label }}
                        </div>
                        <div class="components-tips">
                          <el-tooltip class="item" effect="dark" :content="element.__config__?.expandParams?.tips"
                            placement="top-start" v-if="element.__config__?.expandParams?.tips">
                            <r-icon name="question" size="16" color="var(--primary)" hover></r-icon>
                          </el-tooltip>
                        </div>
                      </div>
                    </div>
                  </draggable>
                </div>
              </div>
            </el-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <div class="center-board">
      <div class="action-bar">
        <el-button class="delete-btn" icon="el-icon-delete" type="text" @click="empty" v-if="!id">
          清空
        </el-button>
      </div>
      <div class="wap-container">
        <div class="wap-cover">
          <el-scrollbar class="center-scrollbar" style="overflow-x:hidden">
            <el-row class="center-board-row" :gutter="formConf.gutter">
              <el-form :size="formConf.size" label-position="top" :disabled="formConf.disabled"
                :label-width="formConf.labelWidth + 'px'" @submit.prevent>
                <draggable class="drawing-board" :list="drawingList" :animation="340" group="componentsGroup" filter=".disabled">
                  <draggable-item v-for="(item, index) in drawingList" :key="item.renderKey" :drawing-list="drawingList"
                    :current-item="item" :index="index" :active-id="activeId" :form-conf="formConf"
                    @activeItem="activeFormItem" @copyItem="drawingItemCopy" @deleteItem="drawingItemDelete" @contextShow="contextShow" :class="{disabled:isTask}"/>
                </draggable>
                <div v-show="!drawingList.length" class="empty-info">
                  从左侧拖入或点选组件进行表单设计
                </div>
              </el-form>
            </el-row>
          </el-scrollbar>
        </div>
      </div>
    </div>

    <right-panel :active-data="activeData" :form-conf="formConf" :show-field="!!drawingList.length"
      @tag-change="tagChange" @fetch-data="fetchData" />

    <!-- <form-drawer
      :visible.sync="drawerVisible"
      :form-data="formData"
      size="100%"
      :generate-conf="generateConf"
    /> -->
    <json-drawer size="60%" :visible.sync="jsonDrawerVisible" :json-str="JSON.stringify(formData)"
      @refresh="refreshJson" />
    <code-type-dialog :visible.sync="dialogVisible" title="选择生成类型" :show-file-name="showFileName" @confirm="generate" />
    <input id="copyNode" type="hidden">
    <rich-text-show ref="richTextShow"></rich-text-show>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import { saveAs } from 'file-saver';
import render from '../components/render/render';
// import FormDrawer from './FormDrawer';
import JsonDrawer from './JsonDrawer';
import RightPanel from './RightPanel';
import {
  inputComponents, formConf, showComponents, systemComponents
} from '../components/generator/config'
import {
  beautifierConf, titleCase, deepClone, isObjectObject
} from '../utils/index'
import {
  makeUpHtml, vueTemplate, vueScript, cssStyle
} from '../components/generator/html'
import { makeUpJs } from '../components/generator/js'
import { makeUpCss } from '../components/generator/css'
import drawingDefalut from '../components/generator/drawingDefalut'
import CodeTypeDialog from './CodeTypeDialog'
import DraggableItem from './DraggableItem'
import {
  getIdGlobal, getFormConf
} from '../utils/db'
import loadBeautifier from '../utils/loadBeautifier';
import componentRegister from "../utils/registerComponent";
import { cloneDeep, isArray } from "@/utils"
import richTextShow from "@/components/rich-text-show"
let beautifier
const emptyActiveData = { style: {}, autosize: {} }
let oldActiveId
let tempActiveData
const idGlobal = getIdGlobal();
const initComponents = (list, type) => {
  let _list = list.map(el => {
    el.__config__.type = type;
    if (!el?.__config__?.expandParams) {
      el.__config__.expandParams = {
        showLabel: true
      };
    } else {
      if (el?.__config__.expandParams.showLabel === undefined) {
        el.__config__.expandParams.showLabel = true;
      }
    }
    //输入型控件存在文件及提示
    if (type == 'TYPE_INPUT') {
      el.__config__.expandParams.descEdit = '';
      el.__config__.expandParams.dialogTips = '';
    }
    //输入星空间
    return el;
  });
  return _list;
}
export default {
  components: {
    draggable,
    render,
    // FormDrawer,
    JsonDrawer,
    RightPanel,
    CodeTypeDialog,
    DraggableItem,
    richTextShow
  },
  inject: ['id','task','approve'],
  data() {
    return {
      logo: "",
      idGlobal,
      formConf,
      inputComponents,
      labelWidth: 100,
      drawingList: deepClone(drawingDefalut),
      drawingData: {},
      activeId: drawingDefalut[0].formId,
      drawerVisible: false,
      formData: {},
      dialogVisible: false,
      jsonDrawerVisible: false,
      generateConf: null,
      showFileName: false,
      activeData: drawingDefalut.length == 0 ? null : drawingDefalut[0],
      leftComponents: [
        {
          title: '显示控件',
          list: initComponents(showComponents, 'TYPE_VIEW'),
          type:'TYPE_VIEW'
        },
        {
          title: '系统参数',
          list: initComponents(systemComponents, 'TYPE_SYSTEM'),
          type:'TYPE_SYSTEM'
        },
        {
          title: '输入控件',
          list: initComponents(inputComponents, 'TYPE_INPUT'),
          type:'TYPE_INPUT'
        }
      ],
      activeComponentType: 0
    }
  },
  computed: {

    transitionStyle() {

      return `transform:translate3D(${-1 * this.activeComponentType * 280}px,0,0)`

    },

    //判断是否要禁止拖拽套件
    groupDisabled() {

      return this.drawingList.some(el => {
        return el?.__config__?.isGroup;
      })

    },

    //判断是否存在组件
    drawingFilter() {
      return this.drawingList.map(el => el?.__config__?.tagIcon);
    },

    isTask(){
      return this.task.task;
    },

    isFilings(){
      return this.task.filings;
    },    
    isHandle(){
      return this.task.handle;
    },
    isApprove(){
      return !!this.approve?.approve;
    }
  },
  watch: {
    // eslint-disable-next-line func-names
    'activeData.__config__.label': function (val, oldVal) {
      if (
        this.activeData.placeholder === undefined
        || !this.activeData.__config__.tag
        || oldActiveId !== this.activeId
      ) {
        return
      }
      this.activeData.placeholder = val
    },
    activeId: {
      handler(val) {
        oldActiveId = val
      },
      immediate: true
    },
    drawingList: {
      handler(val) {
        if (val.length === 0) {
          this.idGlobal = 100;
          this.activeData = {
            __config__: {},
            __slot__: {}
          };
        }
      },
      deep: true
    }
  },
  mounted() {
    componentRegister.init();
    //进入当前页面时动态注册组件
    loadBeautifier(btf => {
      beautifier = btf
    });
    //复制
    if(this.$store.state.approve.form?.formGennerator.length == 0){
      //过滤备案任务
      this.$store.commit('approveSetData', {
        'form.formGennerator': cloneDeep(this.leftComponents[1].list.filter(el=>{
          return !this.filings ? el.__config__.tagIcon != 'filingsStatus' : true;
        }))
      })
    }
    //判断是否存在对应的数据
    if (this.$store.state.approve.form?.formGennerator) {
      this.drawingList = this.$store.state.approve.form?.formGennerator;
      this.drawingData = this.drawingList[0];
    }
    this.activeFormItem(this.drawingList[0]);
  },
  methods: {

    setObjectValueReduce(obj, strKeys, data) {
      const arr = strKeys.split('.')
      arr.reduce((pre, item, i) => {
        if (arr.length === i + 1) {
          pre[item] = data
        } else if (!isObjectObject(pre[item])) {
          pre[item] = {}
        }
        return pre[item]
      }, obj)
    },
    setRespData(component, resp) {
      const { dataPath, renderKey, dataConsumer } = component.__config__
      if (!dataPath || !dataConsumer) return
      const respData = dataPath.split('.').reduce((pre, item) => pre[item], resp)

      // 将请求回来的数据，赋值到指定属性。
      // 以el-tabel为例，根据Element文档，应该将数据赋值给el-tabel的data属性，所以dataConsumer的值应为'data';
      // 此时赋值代码可写成 component[dataConsumer] = respData；
      // 但为支持更深层级的赋值（如：dataConsumer的值为'options.data'）,使用setObjectValueReduce
      this.setObjectValueReduce(component, dataConsumer, respData)
      const i = this.drawingList.findIndex(item => item.__config__.renderKey === renderKey)
      if (i > -1) this.$set(this.drawingList, i, component)
    },
    fetchData(component) {
      const { dataType, method, url } = component.__config__
      if (dataType === 'dynamic' && method && url) {
        this.setLoading(component, true)
        this.$axios({
          method,
          url
        }).then(resp => {
          this.setLoading(component, false)
          this.setRespData(component, resp.data)
        })
      }
    },
    setLoading(component, val) {
      const { directives } = component
      if (Array.isArray(directives)) {
        const t = directives.find(d => d.name === 'loading')
        if (t) t.value = val
      }
    },
    activeFormItem(currentItem) {
      this.activeData = currentItem;
      this.activeId = currentItem?.__config__?.formId;
    },
    onEnd(obj) {
      if (obj.from !== obj.to) {
        this.fetchData(tempActiveData)
        this.activeData = tempActiveData
        this.activeId = this.idGlobal
      }
    },
    addComponent(item, disabled) {
      if (disabled) return;
      const clone = this.cloneComponent(item)
      this.fetchData(clone)
      this.drawingList.push(clone)
      this.activeFormItem(clone)
    },
    cloneComponent(origin) {
      const clone = deepClone(origin)
      const config = clone.__config__
      config.span = this.formConf.span // 生成代码时，会根据span做精简判断
      this.createIdAndKey(clone)
      clone.placeholder !== undefined && (clone.placeholder += cloneDeep(config.label))
      tempActiveData = clone
      return tempActiveData
    },
    createIdAndKey(item) {
      const config = item.__config__
      config.formId = ++this.idGlobal
      config.renderKey = `${config.formId}${+new Date()}` // 改变renderKey后可以实现强制更新组件
      if (config.layout === 'colFormItem') {
        item.__vModel__ = `field${this.idGlobal}`
      } else if (config.layout === 'rowFormItem') {
        config.componentName = `row${this.idGlobal}`
        !Array.isArray(config.children) && (config.children = [])
        delete config.label // rowFormItem无需配置label属性
      }
      if (Array.isArray(config.children)) {
        config.children = config.children.map(childItem => this.createIdAndKey(childItem))
      }
      return item
    },
    AssembleFormData() {
      this.formData = {
        fields: deepClone(this.drawingList),
        ...this.formConf
      };
    },
    generate(data) {
      const func = this[`exec${titleCase(this.operationType)}`]
      this.generateConf = data
      func && func(data)
    },
    execRun(data) {
      this.AssembleFormData()
      this.drawerVisible = true
    },
    execDownload(data) {
      const codeStr = this.generateCode()
      const blob = new Blob([codeStr], { type: 'text/plain;charset=utf-8' })
      saveAs(blob, data.fileName)
    },
    execCopy(data) {
      document.getElementById('copyNode').click()
    },
    empty() {
      this.$confirm('确定要清空所有组件吗？', '提示', { type: 'warning' }).then(
        () => {
          this.drawingList = []
          this.idGlobal = 100
        }
      )
    },
    drawingItemCopy(item, list) {
      let clone = deepClone(item)
      clone = this.createIdAndKey(clone)
      list.push(clone)
      this.activeFormItem(clone)
    },
    drawingItemDelete(index, list) {
      let item = list[index];
      if (item.__config__?.type == 'TYPE_INPUT' && item?.id) {
        //如果是输入组件，且已经提交过的，需要确认一下
        this.$confirm('删除该控件,将会造成任务已填写的数据消失,确定删除么?', '提示', { type: 'warning' }).then(
          () => {
            list.splice(index, 1)
            this.$nextTick(() => {
              const len = this.drawingList.length
              if (len) {
                this.activeFormItem(this.drawingList[len - 1])
              }
            })
          }
        )
      } else {
        list.splice(index, 1)
        this.$nextTick(() => {
          const len = this.drawingList.length
          if (len) {
            this.activeFormItem(this.drawingList[len - 1])
          }
        })
      }
    },
    generateCode() {
      const { type } = this.generateConf
      this.AssembleFormData()
      const script = vueScript(makeUpJs(this.formData, type))
      const html = vueTemplate(makeUpHtml(this.formData, type))
      const css = cssStyle(makeUpCss(this.formData))
      return beautifier.html(html + script + css, beautifierConf.html)
    },
    showJson() {
      this.AssembleFormData()
      this.jsonDrawerVisible = true
    },
    download() {
      this.dialogVisible = true
      this.showFileName = true
      this.operationType = 'download'
    },
    save() {
      this.AssembleFormData();
    },
    copy() {
      this.dialogVisible = true
      this.showFileName = false
      this.operationType = 'copy'
    },
    tagChange(newTag) {
      newTag = this.cloneComponent(newTag)
      const config = newTag.__config__
      newTag.__vModel__ = this.activeData.__vModel__
      config.formId = this.activeId
      config.span = this.activeData.__config__.span
      this.activeData.__config__.tag = config.tag
      this.activeData.__config__.tagIcon = config.tagIcon
      this.activeData.__config__.document = config.document
      if (typeof this.activeData.__config__.defaultValue === typeof config.defaultValue) {
        config.defaultValue = this.activeData.__config__.defaultValue
      }
      Object.keys(newTag).forEach(key => {
        if (this.activeData[key] !== undefined) {
          newTag[key] = this.activeData[key]
        }
      })
      this.activeData = newTag
      this.updateDrawingList(newTag, this.drawingList)
    },
    updateDrawingList(newTag, list) {
      const index = list.findIndex(item => item.__config__.formId === this.activeId)
      if (index > -1) {
        list.splice(index, 1, newTag)
      } else {
        list.forEach(item => {
          if (Array.isArray(item.__config__.children)) this.updateDrawingList(newTag, item.__config__.children)
        })
      }
    },
    refreshJson(data) {
      this.drawingList = deepClone(data.fields)
      delete data.fields
      this.formConf = data
    },
    contextShow(context){
      this.$refs.richTextShow.open(context,'提示信息');
    }
  }
}
</script>

<style lang='scss'>
@import '../styles/home';
</style>
