import {downloadFile} from "@/utils";
export default {
    methods: {
        /**
     *
     * @param {Array|String} can  权限列表
     * @param {*} required 是否全匹配
     * @returns
     */
        $has(can, required) {
            const canArray = this.$store.state.userInfo.btnRule;
            if (!canArray) return false
            const matches = [].concat(can)
            return required
                ? matches.every(n => canArray.includes(n))
                : matches.some(n => canArray.includes(n))
        },
        /**
         * 获取静态文件地址
         */
        $getStaticUrl(value) {
            if (!value) return "";
            if (value.indexOf('http:') != -1 || value.indexOf('https:') != -1) {
                return value;
            } else {
                return process.env.VUE_APP_IMG_URL + value;
            }
        },
        $logout() {
            this.$store.commit('resetUserInfo');
            this.$router.push({ name: 'login', replace: true });
            this.$message.success('已成功退出登录')
        },
        $downloadFile(url,name){
            downloadFile(url,name)
        }
    },
    computed:{
        $indicatorName(){
            return this.$store.state.userInfo.indicatorName;
        },
        $filingsIndicatorName(){
            return this.$store.state.userInfo.filingsIndicatorName;
        },
        $handleIndicatorName(){
            return this.$store.state.userInfo.handleIndicatorName
        },
        $dispatchIndicatorName(){
            return this.$store.state.userInfo.dispatchIndicatorName
        }
    },
    filters: {
        $staticFilter(value) {
            if (!value) return ''
            if (value.indexOf('http:') != -1 || value.indexOf('https:') != -1) {
                return value;
            } else {
                return process.env.VUE_APP_IMG_URL + value;
            }
        },
        $imageUrlAvatar(value){
            if(!!value && (value.indexOf('http:') != -1 || value.indexOf('https:') != -1)){
                return value;
              }else{
                return process.env.VUE_APP_IMG_URL + (value || '/wx-image/avatar-public-none.png')
              }
        }
    }
}