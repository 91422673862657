//工作台页面必须为layout的子路由
import router from "@/router/config";
const routes = {
    path: '/',
    name: 'layout',
    redirect:{name:'index'},
    children: [
        {
            path:'/index',
            name:'index',
            component:router['index'],
            meta:{
                title:'工作台'
            }
        },{
            path:'/indicator',
            name:'indicator',
            component:router['indicator'],
            meta:{
                title:'指标管理',
                auth:'indicator'
            }
        },{
            path:'/article',
            name:'article',
            component:router['articleList'],
            meta:{
                title:'文章管理',
                auth:'article'
            }
        },{
            path:'/article/list',
            name:'article-list',
            component:router['articleList'],
            meta:{
                title:'文章列表',
                auth:'article-list'
            }
        },{
            path:'/article/category',
            name:'article-category',
            component:router['articleCategory'],
            meta:{
                title:'文章分类',
                auth:'article-category'
            }
        },{
            path:'/framework',
            name:'framework',
            component:router['department'],
            meta:{
                title:'组织架构',
                auth:'framework'
            }
        },{
            path:'/task-audit',
            name:'task-audit',
            component:router['task'],
            meta:{
                title:'任务审批',
                auth:'task-audit'
            }
        },{
            path:'/statistics',
            name:'statistics',
            component:router['statistics'],
            meta:{
                title:'统计',
                auth:'statistics'
            }
        },{
            path:'/task/list',
            name:'task-list',
            component:router['taskList'],
            meta:{
                title:'任务管理',
                auth:'task-list'
            }
        },{
            path:'/question/list',
            name:'question-list',
            component:router['question'],
            meta:{
                title:'问题管理',
                auth:'question-list'
            }
        },
        {
            path:'/question/approve/list',
            name:'question-approve-list',
            component:router['questionList'],
            meta:{
                title:'问题审批管理',
                auth:'question-approve-list'
            }
        },{
            path:'/question/statistics',
            name:'question-statistics',
            component:router['questionStatistics'],
            meta:{
                title:'问题统计',
                auth:'question-statistics'
            }
        },{
            path:'/environment/question/category',
            name:'environment-question-category',
            component:router['category'],
            meta:{
                title:'问题分类',
                auth:'environment-question-category'
            }
        },{
            path:'/contract/list',
            name:'contract-list',
            component:router['contractList'],
            meta:{
                title:'合同管理',
                auth:'contract-list'
            }
        },{
            path:'/contract/type/list',
            name:'contract-type-list',
            component:router['contractTypeList'],
            meta:{
                title:'类别管理',
                auth:'contract-type-list'
            }
        },{
            path:'/contract/party/list',
            name:'contract-party-list',
            component:router['partyList'],
            meta:{
                title:'参与方管理',
                auth:'contract-party-list'
            }
        },{
            path:'/filings/indicator',
            name:'filings-indicator',
            component:router['filingsIndicator'],
            meta:{
                title:'备案-指标管理',
                auth:'indicatorfilings'
            }
        },{
            path:'/indicatorfilings/list',
            name:'indicatorfilings-task-list',
            component:router['filingsTaskList'],
            meta:{
                title:'备案-任务管理',
                auth:'indicatorfilings-task-list'
            }
        },{
            path:'/indicatorfilings/task/apporve',
            name:'indicatorfilingsTaskApprove',
            component:router['filingsTask'],
            meta:{
                title:'备案-任务审批',
                auth:'indicatorfilingsTaskApprove'
            }
        },{
            path:'/indicatorfilings/apporve',
            name:'indicatorfilingsApprove',
            component:router['filingsApprove'],
            meta:{
                title:'备案-备案审批',
                auth:'indicatorfilingsApprove'
            }
        },{
            path:'/indicatorfilings/statistics',
            name:'indicatorfilingsStatistics',
            component:router['filingsStatistics'],
            meta:{
                title:'备案-备案任务统计',
                auth:'indicatorfilingsApprove'
            }
        },{
            path:'/approve/list',
            name:'approve-list',
            component:router['reimbursementTemplate'],
            meta:{
                title:'表单模板列表',
                auth:'approve-list'
            }
        },{
            path:'/approve/apply',
            name:'approve-apply',
            component:router['reimbursementMyList'],
            meta:{
                title:'我的审批',
                auth:'approve-apply'
            }
        },{
            path:'approve-dashboard',
            name:'approve/dashboard',
            component:router['reimbursementList'],
            meta:{
                title:'全部审批',
                auth:'approve/dashboard'
            }
        },{
            path:'project/config',
            name:'project-config',
            component:router['projectConfig'],
            meta:{
                title:'五到场配置',
                auth:'project-config'
            }
        },{
            path:'system/area',
            name:'system-area',
            component:router['area'],
            meta:{
                title:'区域管理',
                auth:'system-area'
            }
        },{
            path:'/handle/indicator',
            name:'handle-indicator',
            component:router['handleIndicator'],
            meta:{
                title:'日常工作督办-指标管理',
                auth:'handle-indicator'
            }
        },{
            path:'handle-indicator/task/list',
            name:'handle-indicator-task-list',
            component:router['handleIndicatorTaskList'],
            meta:{
                title:'日常工作督办-任务管理',
                auth:'handle-indicator-task-list'
            }
        },{
            path:'handle-indicator/task/audit',
            name:'handle-indicator-task-audit',
            component:router['handleIndicatorTask'],
            meta:{
                title:'日常工作督办-任务审批',
                auth:'handle-indicator-task-audit'
            }
        },{
            path:'handle-indicator/statistics',
            name:'handle-indicator-statistics',
            component:router['handleIndicatorStatistics'],
            meta:{
                title:'日常工作督办-统计',
                auth:'handle-indicator-statistics'
            }
        },{
            path:'/dispatch/indicator',
            name:'dispatch-indicator',
            component:router['dispatchIndicator'],
            meta:{
                title:'重点工作调度-指标管理',
                auth:'dispatch-indicator'
            }
        },{
            path:'dispatch-indicator/task/list',
            name:'dispatch-indicator-task-list',
            component:router['dispatchIndicatorTaskList'],
            meta:{
                title:'重点工作调度-任务管理',
                auth:'dispatch-indicator-task-list'
            }
        },{
            path:'dispatch-indicator/task/audit',
            name:'dispatch-indicator-task-audit',
            component:router['dispatchIndicatorTask'],
            meta:{
                title:'重点工作调度-任务审批',
                auth:'dispatch-indicator-task-audit'
            }
        },{
            path:'dispatch-indicator/statistics',
            name:'dispatch-indicator-statistics',
            component:router['dispatchIndicatorStatistics'],
            meta:{
                title:'重点工作调度-统计',
                auth:'dispatch-indicator-statistics'
            }
        }
    ],
    component: router['layout'],
    meta: {
        title: ''
    }
}


export default routes