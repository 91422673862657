<template>
    <div>
        <r-dialog ref="rDialog" :footer="false" :border="false">
            <template v-slot:title>
                <el-input placeholder="输入姓名或手机号搜索" v-model="keyword" @keydown.enter.native="search" clearable
                    size="small" class="r-w-220">
                </el-input>
                <el-button size="small" type="primary" class="r-ml-10" @click="search">搜索</el-button>
            </template>
            <div class="search-table">
                <r-table :loader="loader" :columns="columns" :auto="false" ref="table" height="100%"
                    :cell-style="cellStyle" @cell-click="cellClick"></r-table>
            </div>
        </r-dialog>
        <update-employee ref="updateEmployee" @success="refresh"></update-employee>
    </div>
</template>
<script>
import { fetchList as getStaffList } from "@/api/staff/index";
import updateEmployee from './update-employee.vue';
export default {
    components:{
        updateEmployee
    },
    data() {
        return {
            keyword: '',
            columns: [
                {
                    label: '姓名',
                    prop: 'nickname',
                    'min-width': '80px',
                    'show-overflow-tooltip': true
                }, {
                    label: '部门',
                    prop: 'dep.name',
                    'min-width': '120px',
                    'show-overflow-tooltip': true
                }, {
                    label: '职位',
                    prop: 'positionName',
                    'min-width': '120px',
                    'show-overflow-tooltip': true
                }, {
                    label: '手机号',
                    prop: 'phone',
                    'min-width': '120px',
                    'show-overflow-tooltip': true
                }
            ]
        }
    },
    methods: {
        open() {
            this.$refs.rDialog.handleOpen();
        },
        loader(page, pageSize) {
            return getStaffList({
                department: '',
                form: "system",
                subDep: 0,
                page: page,
                pageSize: pageSize,
                keyword: this.keyword,
                isSystemRule:1
            }).then((response) => {
                return response.data;
            });
        },
        search() {
            this.$refs.table.refresh(1);
        },
        refresh(){
            this.$refs.table.refresh();
        },
        cellStyle() {
            return 'cursor: pointer;'
        },
        cellClick(row, column) {
            if (column.property == 'dep.name') {
                this.$emit('depChange',row.dep.id);
            }else{
                this.edit(row);
            }
        },
        edit(row){
            this.$refs.updateEmployee.open({
                employeeId: row.id,
                nickname: row.nickname,
                areaId: row.areaId,
                phone: row.phone,
                hidePhone: row.hidePhone,
                remarks: row.remarks,
                positionName: row.positionName,
                department: row.dep ? [row.dep] : [],
                position: row.position,
                roleData: row.employeeRole?.length> 0 ? row.employeeRole.map(el=>el.roleId) : []
            }, true);
        }
    }

}
</script>

<style lang="scss" scoped>
.search-table {
    height: 60vh;
}
</style>