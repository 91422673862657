/**
 * 日常工作督办
 */

import handleIndicator from "@/views/handle/indicator";
import handleIndicatorTaskList from "@/views/handle/task/list";
import handleIndicatorTask from "@/views/handle/task/index";
import handleIndicatorStatistics from "@/views/handle/statistics/index";
export default {
    handleIndicator,
    handleIndicatorTaskList,
    handleIndicatorTask,
    handleIndicatorStatistics
}
  